import "./JumpToTop.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface JumpToTopProps {
  dissapear: boolean;
}

const JumpToTop = ({dissapear}: JumpToTopProps) => {

  const handleJump = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <button className={`jump-to-top ${dissapear ? "dissapear" : ""}`} onClick={handleJump}>
      <FontAwesomeIcon icon={faArrowUp} className="p-green-h"/>
    </button>
  );
};

export default JumpToTop;
