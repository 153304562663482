import axios from '../../../utils/axios';
import { useModal } from '../../../contexts/ModalProvider';
import { useSnackbar } from '../../../contexts/SnackbarProvider';
import { useRef, useState } from 'react';
import { ReactComponent as Upload } from '../../../images/upload-icon.svg';
import './ProfilePictureModal.scss';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import Button from '../../buttons/Button';

const ProfilePictureModal = () => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {openErrorSnackbar} = useSnackbar();
  const {closeModal} = useModal();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) uploadFile(e.dataTransfer.files[0]);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) uploadFile(e.target.files[0]);
  };

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const uploadFile = (file: File) => {
    const fileExtension = `.${file.name.split('.').pop()}`.toLowerCase();
    if (fileExtension !== '.png' && fileExtension !== '.jpg' && fileExtension !== '.jpeg') {
      openErrorSnackbar("Obrázek musí být ve formátu .png, .jpg nebo .jpeg");
      return;
    }
    const formData = new FormData();
    formData.append('profile_image', file);
    axios.post('/api/users/user/change-profile-image', formData, {
      headers: {'Content-Type': 'multipart/form-data'},
      })
    .then(() => {
      sessionStorage.removeItem("profile" + userInfo.id);
      location.reload();
      closeModal();
    })
    .catch((err: any) => {
      openErrorSnackbar(err.response.data.cz);
    })
  };

  return (
    <div className="profile-picture-modal">
      <h1 className='font-20-b'>Nahrát obrázek</h1>
      <div className='upload-container'>
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input ref={inputRef} type="file" hidden id="input-file-upload" multiple={true} onChange={handleChange} accept="image/png, image/jpeg, image/jpg"/>
          <Upload onDrop={() => uploadFile}/>
          { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
        </form>
        <h2 className='font-16-b'>Přetáhněte sem nový profilový obrázek</h2>
        <Button size='medium' onClick={onButtonClick}>Vyberte soubor</Button>
      </div>
    </div>
  )
}

export default ProfilePictureModal;