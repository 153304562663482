import { useModal } from '../../contexts/ModalProvider';
import './ChangePassword.scss'
import axios from "axios";
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useState } from 'react';
import Login from 'components/authetication/Login';

type ChangePasswordProps = {
  token: string;
}

const ChangePassword = ({token}: ChangePasswordProps) => {
  const { closeModal, showModal } = useModal();
  const { openSnackbar, openErrorSnackbar } = useSnackbar();
  const [ password, setPassword ] = useState<string>("");
  const [ passwordConf, setPasswordConf ] = useState<string>("");
  const [ error, setError ] = useState<string | undefined>(undefined);
  const [ errorConf, setErrorConf ] = useState<string | undefined>(undefined);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState<boolean>(false);

  const changeValidation = () => {
    setError(undefined);
    setErrorConf(undefined);
    if (!password) {
      setError("Toto pole je povinné");
      return;
    }
    if (!passwordConf) {
      setErrorConf("Toto pole je povinné");
      return;
    }
    if (password !== passwordConf) {
      setErrorConf("Hesla se neshodují");
      return;
    }
    if (password.length < 8) {
      setError("Heslo musí být minimálně 8 znaků dlouhé");
      return;
    }
    if (password.length > 100) {
      setError("Heslo nesmí být delší než 100 znaků");
      return;
    }
    handleChange();
  }

  const handleChange = () => {
    axios.post("/api/users/reset-password", {
      new_password: password,
      new_password_again: passwordConf,
      code: token
    })
    .then(() => {
      openSnackbar("Heslo bylo úspěšně změněno!");
      setIsSuccessfullySubmitted(true);
      setTimeout(() => {
        showModal(<Login/>)
      }, 750);
    }).catch((err) => {
      if (!err.response.data.en) {
        console.error(err);
        setError("Někde nastala chyba zkuste to znovu");
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!");
      } else if (err.reponse.status === 404) {
        openErrorSnackbar("Tento kód již není platný!");
        closeModal();
      } else {
        console.error(err);
        setError("Někde nastala chyba zkuste to znovu");
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!");
      }
    })
  }

  return (
    <section className='change-password'>
      <h1 className='font-24-b'>Změnit heslo</h1>
      <label className='font-14'>Heslo:</label>
      <input className={`${error ? "border-red-600" : ""}`} type="password" placeholder='Zadejte heslo...' onChange={(e) => {setPassword(e.target.value); if(error || errorConf) {setError(undefined);setErrorConf(undefined)}}}/>
      <p className={`${error ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{error}!</p>
      <label className='font-14'>Heslo znovu:</label>
      <input className={`${errorConf ? "border-red-600" : ""}`} type="password" placeholder='Zadejte heslo znovu...' onChange={(e) => {setPasswordConf(e.target.value); if(error || errorConf) {setError(undefined);setErrorConf(undefined)}}}/>
      <p className={`${errorConf ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errorConf}!</p>
      {isSuccessfullySubmitted && (<p className="ml-0.5 text-sm text-green-600 successfullySubmitted">Heslo bylo úspěšně změněno.</p>)}
      <div className='buttons'>
        <button className='close-button s-green-bg-h p-green' type="button" onClick={closeModal}>Zavřít</button>
        <button className='change-button p-green-bg-h l-green' onClick={changeValidation}>Změnit</button>
      </div>
    </section>
  )
}

export default ChangePassword;