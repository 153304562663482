export const getTeachingUnitRowAmount = () => {
  const width = window.innerWidth;
  if (width < 1165) return 2;
  if (width < 1560) return 3;
  if (width < 1955) return 4;
  if (width < 2350) return 5;
  return 5;
}

export const getCategoryRowAmount = () => {
  const width = window.innerWidth;
  if (width < 1054) return 2;
  if (width < 1299) return 3;
  if (width < 1544) return 4;
  if (width < 1789) return 5;
  if (width < 2034) return 6;
  if (width < 2279) return 7;
  if (width < 2524) return 8;
  if (width < 2769) return 9;
  if (width < 3014) return 10;
  if (width < 3259) return 11;
  if (width < 3504) return 12;
  return 5;
}