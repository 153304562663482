import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "../dropdown/Dropdown";
import "./SortBy.scss";

export interface SortByProps {
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
}

const SortBy = ({ setSortBy }: SortByProps) => {
  const options = [
    {"arg": "-number_of_downloads", "desc": "Počet stažení", "icon": faArrowDown},
    {"arg": "number_of_downloads", "desc": "Počet stažení", "icon": faArrowUp},
    {"arg": "-avg_rating", "desc": "Hodnocení", "icon": faArrowDown},
    {"arg": "avg_rating", "desc": "Hodnocení", "icon": faArrowUp},
    {"arg": "-number_of_lessons", "desc": "Počet hodin", "icon": faArrowDown},
    {"arg": "number_of_lessons", "desc": "Počet hodin", "icon": faArrowUp},
  ]

  const handleSortClick = (option: string) => {
    setSortBy(option);
  }

  return (
    <div className="sort-by">
      <Dropdown label="Seřadit podle" defaultClasses={false} className="sort-by-button s-green-bg-h p-blue font-16-b">
        {options.map((option, index) => {
          return (
            <Dropdown.Item key={index} defaultClasses={false} onClick={() => handleSortClick(option.arg)} className={"sort-by-menu-button"}>
              <div className="button-wrapper">
                <label>{option.desc}</label>
                <FontAwesomeIcon icon={option.icon} />
              </div>
            </Dropdown.Item>
          )
        })}
      </Dropdown>
    </div>
  );
};

export default SortBy;
