import { useState } from "react";
import Button from "../../../buttons/Button";
import "./AlternativeModal.scss";
import axios from "../../../../utils/axios";
import { setReloadTeachingUnit } from "../../../../redux/utilsSlice";
import { useDispatch } from "react-redux";
import { useModal } from "../../../../contexts/ModalProvider";
import { useSnackbar } from "../../../../contexts/SnackbarProvider";

interface AlternativeModalProps {
  parentId: string | undefined;
}

const AlternativeModal = ({parentId}: AlternativeModalProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const { closeModal } = useModal();
  const { openSnackbar, openErrorSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addAlternative = async () => {
    if (parentId === undefined) return;
    const validationUrl: string = window.location.protocol + "//" + window.location.host + "/teaching-unit/";
    if (!inputValue.startsWith(validationUrl)) {
      openErrorSnackbar("Alternativa musí být odkaz na jiný výukový celek na našem webu!");
      return;
    }
    let teachingUnitId: string | undefined = "";
    if (inputValue.endsWith("/")) {
      teachingUnitId = inputValue.slice(0, -1).split("/").pop();
    } else {
      teachingUnitId = inputValue.split("/").pop();
    }
    if (!teachingUnitId) {
      openErrorSnackbar("Alternativa musí být odkaz na jiný výukový celek na našem webu!");
      return;
    }
    if (teachingUnitId === parentId) {
      openErrorSnackbar("Alternativa nemůže být stejný výukový celek!");
      return;
    }
    axios.post("/api/teaching-units/" + parentId + "/alternatives", {"alternative": teachingUnitId})
    .then(() => {
      dispatch(setReloadTeachingUnit("alternatives"));
      closeModal();
      openSnackbar("Alternativa byla přidána!");
    })
    .catch((err: any) => {
      if (err.response.status === 409) {
        openErrorSnackbar("Tato alternativa již existuje!");
        return;
      } else {
        openErrorSnackbar("Nepodařilo se přidat alternativu!");
        console.error(err);
      }
    })
  }

  return (
    <div className="alternative-modal">
      <h2 className="font-16-b">Vložte odkaz na alternativu</h2>
      <input className="alternative-input" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
      <div className="footer-alternative">
        <Button size={"medium"} color={"primary"} onClick={() => addAlternative()}>Přidat</Button>
      </div>
    </div>
  )
};

export default AlternativeModal;