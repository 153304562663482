import LoadingScreen from "../../loading-screen/LoadingScreen";
import { useEffect, useState } from "react";
import "./CurriculumsWindow.scss";
import axios from "../../../utils/axios";

interface CurriculumsWindowProps {
  id: string | undefined;
}

const CurriculumsWindow = ({id}: CurriculumsWindowProps) => {
  const [curriculums, setCurriculums] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchCurriculums();    
  }, [])

  const fetchCurriculums = async () => {
    const response = await axios.get(`/api/teaching-units/${id}/curriculums`);
    if (response.data.length > 0) {
      const loadedCurriculums: string[] =[]
      response.data.forEach((curriculum: any) => {
        loadedCurriculums.push(curriculum.name);
      }); 
      setCurriculums(loadedCurriculums);
    }
    setLoading(false);
  };

  return (
    <section className="curriculums-window">
      <h2 className="font-16-b mt-1">Výstupy rámcových vzdělávacích programů</h2>
      {loading ?
        <LoadingScreen upper/>
      :
        <>
          <ul className="curriculums-list">
            {curriculums.map((curriculum, index) => {
              return (
                <li key={index}>{curriculum}</li>
              )
            })}
          </ul>
          {curriculums.length === 0 && <p>V tomto výukovém celku nejsou žádné výstupy rámcových vzdělávacích programů.</p>}
        </>
      }
    </section>
  )
};

export default CurriculumsWindow;