import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { ReactComponent as ArrowDown } from '../../images/arrow-down.svg';
import './UploadMaterialModal.scss'
import { useEffect, useState } from 'react';
import Dropdown from '../dropdown/Dropdown';
import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "./react-draft-wysiwyg.css";
import useFilterLogic, { selectedGradeTypeIdsUpload, filteredGradesUpload } from '../filter-logic/useFilterLogic';
import DropdownPopover from '../dropdown-popover/DropdownPopover';
import { iCategory, iGrade, iGradeType, iSubject } from '../../interfaces/filter';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@headlessui/react'
import Button from '../buttons/Button';
import { useModal } from '../../contexts/ModalProvider';
import { iMaterialData, iFullMaterialData, iFullMaterialDataWithFile } from '../../interfaces/materialData';
import { findSubject } from '../../utils/findSubject';
import axios from '../../utils/axios';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { setReloadTeachingUnit } from 'redux/utilsSlice';
import { useDispatch } from 'react-redux';
import { getCachedObjects } from 'utils/getCachedKeyword';

const blankMaterialData: iFullMaterialData = {
  name: '',
  lessonCount: 0,
  description: '',
  subject: {"id": 0, "name": "Předmět"},
  gradeType: {"id": 0, "name": "Ročník"},
  grade: {"id": 0, "name": "Třída", "grade_type": 0, "grade_type_name": ""},
  category: {"id": 0, "name": "Kategorie"},
  lessons: [],
  language: {"id": 1, "name": "Čeština"},
  curriculums: [],
  keywords: []
}

interface UploadMaterialModalProps {
  rawMaterialData?: iMaterialData;
  file?: File;
  loadedMaterialData?: iFullMaterialData;
}

const UploadMaterialModal = ({rawMaterialData, file, loadedMaterialData}: UploadMaterialModalProps) => {
  const [materialData, setMaterialData] = useState<iFullMaterialData>(blankMaterialData);
  const [keywordsInputValue, setKeywordsInputValue] = useState<string>('');
  const [curriculumInputValue, setCurriculumInputValue] = useState<string>('');
  const [materialDescriptionEditorState, setMaterialDescriptionEditorState] = useState(EditorState.createEmpty());
  const [otherOptions, setOtherOptions] = useState<boolean>(false);
  const [mutation, setMutation] = useState<boolean>(false);
  const [categories, setCategories] = useState<iCategory[]>([]);
  const [skipEffect, setSkipEffect] = useState<boolean>(false);
  const {closeModal} = useModal();
  const {openSnackbar, openErrorSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    gradeTypes,
    languages,
    filteredSubjects,
    subjectFilter,
    handleSubjectFilter,
  } = useFilterLogic();

  useEffect(() => {
    if (materialData === undefined) closeModal();
    if (rawMaterialData && rawMaterialData.parent !== undefined) newMutation();
    else if (rawMaterialData !== undefined) newMaterial();
    else loadMaterial();
  }, []);

  useEffect(() => {
    if (materialData.subject.id === 0) return;
    if (skipEffect) setSkipEffect(false);
    else changeData("category", {"id": 0, "name": "Kategorie"});
    fetchCategories(materialData.subject.id);
  }, [materialData.subject.id])

  const newMaterial = () => {
    if (rawMaterialData === undefined) return;
    const newMaterialData = {
      name: rawMaterialData.name,
      lessonCount: rawMaterialData.lessons.length,
      description: "<p>" + rawMaterialData.description + "</p>",
      subject: {"id": 0, "name": "Předmět"},
      gradeType: {"id": 0, "name": "Ročník"},
      grade: {"id": 0, "name": "Třída", "grade_type": 0, "grade_type_name": ""},
      category: {"id": 0, "name": "Kategorie"},
      lessons: rawMaterialData.lessons,
      language: {"id": 1, "name": "Čeština"},
      curriculums: rawMaterialData.curriculums,
      keywords: []
    }
    setMaterialData(newMaterialData);
    setMaterialDescriptionEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(("<p>" + rawMaterialData.description + "</p>") || '<p></p>').contentBlocks)));
    if (rawMaterialData.subjectName) fetchSubject(rawMaterialData.subjectName);
  }

  const loadMaterial = () => {
    if (loadedMaterialData === undefined) return;
    setSkipEffect(true);
    setMaterialData(loadedMaterialData);
    setMaterialDescriptionEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft((loadedMaterialData.description) || '<p></p>').contentBlocks)));
    changeData("subject", loadedMaterialData.subject);
    if (loadedMaterialData.parent) setMutation(true);
  }

  const newMutation = () => {
    setMutation(true);
    if (rawMaterialData === undefined || rawMaterialData.subject === undefined || rawMaterialData.gradeType === undefined || rawMaterialData.grade === undefined || rawMaterialData.language === undefined || rawMaterialData.keywords === undefined) return;
    if (rawMaterialData.category === undefined) {
      rawMaterialData.category = {"id": 0, "name": "Kategorie"};
    }
    const newMaterialData = {
      parent: rawMaterialData.parent,
      name: rawMaterialData.name,
      lessonCount: rawMaterialData.lessons.length,
      description: rawMaterialData.description,
      subject: rawMaterialData.subject,
      gradeType: rawMaterialData.gradeType,
      grade: rawMaterialData.grade,
      category: rawMaterialData.category,
      lessons: rawMaterialData.lessons,
      language: rawMaterialData.language,
      curriculums: rawMaterialData.curriculums,
      keywords: rawMaterialData.keywords
    }
    setSkipEffect(true);
    setMaterialData(newMaterialData);
    setMaterialDescriptionEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft((rawMaterialData.description) || '<p></p>').contentBlocks)));
  }

  const fetchSubject = async (subjectName: string) => {
    const bestSubject: iSubject = await findSubject(subjectName);
    if (bestSubject.name !== "Předmět") openSnackbar("Předmět byl automaticky vyplněn jako: " + bestSubject.name)
    setMaterialData((prev: iFullMaterialData) => {
      return {
        ...prev,
        subject: bestSubject
      }
    });
  }

  const fetchCategories = async (subjectId: number) => {
    const categoriesLeaves = await getCachedObjects("categories/subjects/leaves");
    setCategories(() => {
      const filteredCategories = categoriesLeaves.filter((category: any) => category.subject === subjectId) || [];
      if (filteredCategories.length === 0) return [];
      return filteredCategories[0].categories;
    });
  }

  const changeData = (type: string, value: string | number | string[] | iGrade | iGradeType) => {
    if (type === "gradeType" && typeof value === "object" && "id" in value && materialData.grade.grade_type !== value.id) {
      setMaterialData((prev: iFullMaterialData) => {
        return {
          ...prev,
          ["grade"]: {"id": 0, "name": "Třída", "grade_type_name": "", "grade_type": 0}
        }
      })
    }
    setMaterialData((prev: iFullMaterialData) => {
      return {
        ...prev,
        [type]: value
      }
    })
  }

  const onKeywordCloseButtonClick = (keyword: string) => {
    setMaterialData((prev: iFullMaterialData) => {
      const updatedKeywords = prev.keywords.filter((sTag: string) => sTag !== keyword);
      return {
        ...prev,
        keywords: updatedKeywords
      };
    });
  }

  const onCurriculumCloseButtonClick = (index: number) => {
    setMaterialData((prev: iFullMaterialData) => {
      const updatedCurriculums = prev.curriculums.filter((_curriculum: string, pIndex: number) => {
        return pIndex !== index;
      });
      return {
        ...prev,
        curriculums: updatedCurriculums
      };
    });
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && keywordsInputValue === '') {
      if (materialData.keywords.length > 0) {
        setMaterialData((prev: iFullMaterialData) => {
          const updatedKeywords = prev.keywords.slice(0, -1);
          return {
            ...prev,
            keywords: updatedKeywords
          };
        });
      }
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (keywordsInputValue.trim() !== '') {
        if (materialData.keywords.length > 19 || materialData.keywords.includes(keywordsInputValue.trim())) return;
        setMaterialData((prev: iFullMaterialData) => {
          return {
            ...prev,
            keywords: [...prev.keywords, keywordsInputValue.trim()]
          };
        });
        setKeywordsInputValue('');
      }
    }
  };

  const handleCurriculumAdd = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (curriculumInputValue.trim() !== '') {
        if (materialData.curriculums.length > 100 || materialData.curriculums.includes(curriculumInputValue.trim())) return;
        setMaterialData((prev: iFullMaterialData) => {
          return {
            ...prev, 
            curriculums: [...prev.curriculums, curriculumInputValue.trim()]};
        });
        setCurriculumInputValue('');
      }
    }
  };

  const submit = () => {
    const finalMaterialData = JSON.parse(JSON.stringify(materialData));
    finalMaterialData.name = finalMaterialData.name.trim();
    if (finalMaterialData.category.id === 0 && categories.length === 0) finalMaterialData.category = undefined;
    if (finalMaterialData.name.length > 63) return openErrorSnackbar('Název výukového celku nesmí být delší než 63 znaků!');
    if (finalMaterialData.name.length < 6) return openErrorSnackbar('Název výukového celku nesmí být kratší než 6 znaků!');
    if (finalMaterialData.lessonCount < 1) return openErrorSnackbar('Počet hodin musí být alespoň 1!');
    if (finalMaterialData.lessonCount > 100) return openErrorSnackbar('Počet hodin musí být méně než 100!');
    if (finalMaterialData.description.length < 16) return openErrorSnackbar('Popis výukového celku musí být alespoň 16 raw znaků dlouhý!');
    if (finalMaterialData.description.length > 8192) return openErrorSnackbar('Popis výukového celku nesmí být 8192 znaků dlouhý!');
    if (finalMaterialData.subject.id === 0) return openErrorSnackbar('Vyberte prosím předmět!');
    if (finalMaterialData.gradeType.id === 0) return openErrorSnackbar('Vyberte prosím ročník!');
    if (finalMaterialData.grade.id === 0) return openErrorSnackbar('Vyberte prosím třídu!');
    if (finalMaterialData.category?.id === 0 && categories.length > 0) return openErrorSnackbar('Vyberte prosím kategorii!');
    if (finalMaterialData.keywords.length < 1) return openErrorSnackbar('Přidejte alespoň 1 klíčové slovo!');
    if (finalMaterialData.keywords.length > 20) return openErrorSnackbar('Nesmíte mít více jak 20 klíčových slov!');
    if (finalMaterialData.language.id === 0) return openErrorSnackbar('Vyberte prosím jazyk!');
    if (finalMaterialData.curriculums.length > 100) return openErrorSnackbar('Nesmíte mít více jak 100 RVP výstupů!');
    if (rawMaterialData && rawMaterialData.parent !== undefined) uploadMutation(finalMaterialData);
    else if (loadedMaterialData === undefined) uploadFile(finalMaterialData);
    else updateFile(finalMaterialData);
  }

  const updateFile = (finalMaterialData: iFullMaterialData) => {
    if (finalMaterialData === undefined || loadedMaterialData?.id === undefined) return;
    closeModal();
    axios.put(`/api/teaching-units/${loadedMaterialData.id}/update`, finalMaterialData)
    .then(() => {
      openSnackbar("Výukový celek byl úspěšně upraven!");
      dispatch(setReloadTeachingUnit("update"));
    })
    .catch((error) => {
      openErrorSnackbar(error.response.data.cz);
      console.error(error.response);
    });
  }

  const uploadFile = (finalMaterialData: iFullMaterialDataWithFile) => {
    if (file === undefined) return;
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      finalMaterialData.zipFile = base64String;
  
      axios.post("/api/teaching-units/new", finalMaterialData)
        .then((response) => {
          openSnackbar("Výukový celek byl úspěšně nahrán!");
          closeModal();
          navigate(`/teaching-unit/${response.data.id}`);
        })
        .catch((error) => {
          openErrorSnackbar(error.response.data.cz);
          console.error(error.response);
        });
    };
    reader.readAsDataURL(file);
  }

  const uploadMutation = (finalMaterialData: iFullMaterialDataWithFile) => {
    if (file === undefined) return;
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      finalMaterialData.zipFile = base64String;
  
      axios.post(`/api/teaching-units/${finalMaterialData.parent}/mutations/new`, finalMaterialData)
        .then((response) => {
          openSnackbar("Mutace byla úspěšně nahrána!");
          closeModal();
          navigate(`/teaching-unit/${response.data.id}`);
        })
        .catch((error) => {
          openErrorSnackbar(error.response.data.cz);
          console.error(error.response);
        });
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className="upload-material-modal">
      <div className="modal-header">
        {loadedMaterialData ?
          <h3 className='font-20-b'>Změnit materiál</h3>
        :
          <>
            {rawMaterialData?.parent ?
              <h3 className='font-20-b'>Přidat mutaci</h3>
            :
              <h3 className='font-20-b'>Přidat materiál</h3>
            }
          </>
        }
      </div>
      <div className="modal-body">
        <div className='input-div'>
          <label htmlFor="name-input" className='font-14'>Název</label>
          <input
            type="text"
            className='name-input modal-input font-14'
            id='name-input'
            value={materialData.name}
            maxLength={63}
            onChange={(e) => changeData("name", e.target.value)}
          />
        </div>
        <div className='input-div'>
          <label htmlFor="lessons-count-input" className='font-14'>Počet hodin</label>
          <input
            type="number"
            min={1}
            max={99}
            className='lessons-count-input modal-input font-14'
            id='lessons-count-input'
            value={materialData.lessonCount}
            onChange={(e) => changeData("lessonCount", Number(e.target.value))}
          />
        </div>
        <div className='input-div'>
          <label htmlFor="material-description" className='font-14'>Popis výukového celku</label>
          <WysiwygEditor
            editorState={materialDescriptionEditorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={{fontFamily: 'Plus Jakarta Sans'}}
            toolbar={{
              options: ['inline', 'fontSize', 'list', 'emoji', 'remove', 'history'],
              inline: {options: ['bold', 'italic', 'underline', 'strikethrough']}
            }}
            onEditorStateChange={ (newState: any) => {
              let hasAtomicValue = false
              newState.getCurrentContent().blockMap.forEach((element: any) => {
                if (element.type === "atomic") hasAtomicValue = true
              })
              if (hasAtomicValue) {
                alert("Používáte nepodporované znaky. Zkopírujte text do poznámkového bloku a obsah znovu zkopírujte a vložte!");
                return;
              }
              const text = draftToHtml(convertToRaw(newState.getCurrentContent()));
              if (text.length > 8191) return alert('Popis materiálu nesmí být delší než 8191 raw znaků!');
              setMaterialDescriptionEditorState(newState);
              setMaterialData((prev: iFullMaterialData) => {
                return {
                  ...prev,
                  description: text
                }
              });
            }}
          />
        </div>
        <div className="subject-gradeType-grade">
          <div className="subject">
            <label htmlFor="subject-dropdown" className='font-14'>Předmět</label>
            <DropdownPopover className={"dropdown"}
              buttonClassName={"button dropdown-subjects font-14"}
              buttonDisabled={mutation}
              buttonChildren={
                <>
                  <span>{materialData.subject.name}</span>
                  <FontAwesomeIcon icon={faChevronDown} className="ml-2 h-3" />
                </>
              }
              panelClassName={"panel"}
              panelChildren={
                <> 
                  <label className='font-14-b label'>Předměty</label>
                  <input className="input-filter font-16 subjects" placeholder='Zadejte název předmětu...' value={subjectFilter.value} onChange={handleSubjectFilter}/>
                  <div className='item-list'>
                    {filteredSubjects.value.map((object, index) => {
                      return (
                        <Popover.Button key={index} className='item' onClick={() => changeData("subject", object)}>{object.name}</Popover.Button>
                      )
                    })}
                  </div>
                </>
              }
            />
          </div>
          <div className="gradeType">
            <label htmlFor="gradeType-dropdown" className='font-14'>Ročník</label>
            <Dropdown
              id='gradeType-dropdown'
              className='font-14'
              label={materialData.gradeType.name}
              defaultClasses={false}
              menuClasses={'dropdown-menu'}
            >
              {gradeTypes.value.map((gradeType, index) => (
                <Dropdown.Item key={index} defaultClasses={false} className='dropdown-option-button font-14' onClick={() => {changeData("gradeType", {"id": gradeType.id, "name": gradeType.name}); selectedGradeTypeIdsUpload.value = [gradeType.id]}}>
                  {gradeType.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
          <div className="grade">
            <label htmlFor="grade-dropdown" className='font-14'>Třída</label>
            <Dropdown
              id='grade-dropdown'
              className='font-14'
              label={materialData.grade.name}
              defaultClasses={false}
              menuClasses={'dropdown-menu'}
            >
              {filteredGradesUpload.value.map((grade, index) => (
                <Dropdown.Item key={index} defaultClasses={false} className='dropdown-option-button font-14' onClick={() => changeData("grade", {"id": grade.id, "name": grade.name, "grade_type": grade.grade_type, "grade_type_name": grade.grade_type_name})}>
                  {grade.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="category-assign-wrapper">
          <div className="category-assign">
              <label htmlFor="category-dropdown" className='font-14'>Kategorie</label>
              <Dropdown
                id='category-dropdown'
                className='font-14'
                label={categories.length !== 0 ? materialData.category.name : "Předmět neobsahuje žádné kategorie"}
                disabled={categories.length === 0}
                defaultClasses={false}
                menuClasses={'dropdown-menu'}
              >
                {categories.map((category, index) => (
                  <Dropdown.Item key={index} defaultClasses={false} className='dropdown-option-button font-14' onClick={() => changeData("category", {"id": category.id, "name": category.name})}>
                    {category.name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
        </div>
        <div className='input-div keywords-wrapper'>
          <div className="keywords-container">
            <label htmlFor="keywords-input" className='font-14'>Klíčová slova: </label>
            <div className="keywords">
              {materialData.keywords.length ? null :
                <span className="font-12 mt-1">Pro přidání klíčového slova stikněte Enter...</span>
              }
              {materialData.keywords.map((keyword, index) => (
                <div key={index} className="keyword-tag">
                  <div className="keyword-name font-12">{keyword}</div>
                  <CloseIcon className="close-icon" onClick={() => onKeywordCloseButtonClick(keyword)} />
                </div>
              ))}
            </div>
          </div>
          <input
            type="text"
            className='keywords-input modal-input font-14'
            id='keywords-input'
            maxLength={63}
            value={keywordsInputValue}
            onChange={(e) => setKeywordsInputValue(e.target.value)}
            onKeyDown={handleKeyDown} />
        </div>
        <div className="input-div">
          {materialData.lessons && materialData.lessons.length > 0 &&
            <>
              <label htmlFor="lessons" className='font-14'>Výukové hodiny</label>
              <div className='lessons modal-input' id='lessons'>
                {
                  materialData.lessons.map((lessonName: string, index: number) => <div className='lesson-block font-12 l-blue-bg' key={index}>{lessonName}</div>)
                }
              </div>
            </>
          }
        </div>
        <div className="other-options">
          <div className="other-options-heading" onClick={() => setOtherOptions(!otherOptions)}>
            <span className='font-14-b p-blue'>Další možnosti</span>
            <ArrowDown className={`p-blue ${otherOptions ? 'active' : ''}`} />
          </div>
          <div className="other-options-body">
            <div className={`other-options-wrapper ${otherOptions ? 'shown' : ''}`}>
              <div className={`input-div ${otherOptions ? '' : 'hidden'}`}>
                <label htmlFor="language-input" className='font-14'>Jazyk</label>
                <Dropdown
                  id='language-dropdown'
                  label={materialData.language.name}
                  defaultClasses={false}
                  className={`category-dropdown font-14`}
                  menuClasses='dropdown-menu'
                >
                  {
                    languages.value.map((language, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => changeData("language", {"id": language.id , "name": language.name})}
                        defaultClasses={false}
                        className='category-dropdown-option-button font-14'
                      >
                        {language.name}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown>
              </div>
              <div className={`input-div ${otherOptions ? '' : 'hidden'}`}>
                <label htmlFor="rvp-expectations" className='font-14'>Očekávané výstupy dle RVP</label>
                <input
                  type="text"
                  className='modal-input rvp-expectations font-14'
                  id='rvp-expectations'
                  value={curriculumInputValue}
                  maxLength={1023}
                  placeholder="Přidejte výstup..."
                  onChange={(e) => setCurriculumInputValue(e.target.value)}
                  onKeyDown={handleCurriculumAdd}
                />
              </div>
              <div className={`curriculums-container ${materialData.curriculums.length > 0 ? "" : "hidden"}`}>
                {materialData.curriculums.map((curriculum, index) => (
                  <div className='curriculum font-14 l-blue-bg' key={index}><span>{curriculum}</span><CloseIcon className="close-icon" onClick={() => onCurriculumCloseButtonClick(index)} /></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        {loadedMaterialData ?
          <Button size='large' onClick={submit}>Změnit</Button>
        :
          <>
            {rawMaterialData?.parent ?
              <Button size='large' onClick={submit}>Nahrát mutaci</Button>
            :
              <Button size='large' onClick={submit}>Nahrát soubor</Button>
            }
          </>
        }
      </div>
    </div>
  )
}

export default UploadMaterialModal;