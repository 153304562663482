import ProfileBadge from "../profile-badge/ProfileBadge";
import { useModal } from "../../contexts/ModalProvider";
import ProfilePictureModal from "./modals/ProfilePictureModal";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";

interface InfoData {
  published_count: number;
  download_count: number;
  average_rating: number;
}

interface InfoProps {
  userId: string | undefined;
  profileBadge?: boolean;
}

const Info = ({userId, profileBadge = false}: InfoProps) => {
  const { showModal } = useModal();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const [stats, setStats] = useState<InfoData>({"average_rating": 0, "download_count": 0, "published_count": 0});

  useEffect(() => {
    fetchUserStats();
  }, [])

  const fetchUserStats = async () => {
    const response = await axios.get("/api/authors/" + userId + "/stats");
    setStats(response.data);
  }

  const showProfilePictureModal = () => {
    showModal(<ProfilePictureModal />);
  }

  return (
    <div className="info">
      <div className="flex flex-col items-start">
        {profileBadge &&
          <div className="self-center max-w-[800px]">
            <ProfileBadge large settings name userId={userInfo.id} onClickSettings={showProfilePictureModal}/>
          </div>
        }
        <div className="flex mt-2 self-center">
          <div className="flex flex-col items-start pr-4">
            <div>Počet publikovaných materiálů:</div>
            <div>Celkový počet stažení:</div>
            <div>Průměrné hodnocení materiálů:</div>
          </div>
          <div className="flex flex-col items-start font-bold">
            <div>{stats.published_count}</div>
            <div>{stats.download_count ? stats.download_count : 0}x</div>
            <div>{stats.average_rating === null ? "0" : Math.round(stats.average_rating*100)/100}/5</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info;
