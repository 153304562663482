import "./AlternativesWindow.scss";
import PillButton from "../../buttons/PillButton";
import { iTeachingUnit } from "interfaces/teachingUnit";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import RecommendedUnit from "../../recommended-unit/RecommendedUnit";
import { useModal } from "../../../contexts/ModalProvider";
import AlternativeModal from "./alternative-modal/AlternativeModal";

interface AlternativesWindowProps {
  isAuthor: boolean | undefined;
  alternatives: iTeachingUnit[] | undefined;
  parentId: string | undefined;
}

const AlternativesWindow = ({isAuthor, alternatives, parentId}: AlternativesWindowProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const { showModal } = useModal();

  return (
    <section className="alternatives-window">
      {(userInfo.is_staff || isAuthor) &&
        <PillButton color="primary" size="medium" className="add-new-alternative-button" onClick={() => showModal(<AlternativeModal parentId={parentId}/>)}>+ Přidat novou alternativu</PillButton>
      }
      {alternatives && alternatives.length === 0 && <p className="font-16-b no-alternatives">Tato výuka zatím nemá žádné alternativy</p>}
      <div className="alternatives">
        {alternatives && alternatives.map((alternative: any, index) => (
          <RecommendedUnit key={index} {...alternative} parentId={parentId} removable={(isAuthor || userInfo.is_staff)}/>
        ))}
      </div>
    </section>
  )
};

export default AlternativesWindow;