import "./MutationsWindow.scss";
import PillButton from "../../buttons/PillButton";
import { iTeachingUnit } from "interfaces/teachingUnit";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import RecommendedUnit from "../../recommended-unit/RecommendedUnit";
import { useModal } from "../../../contexts/ModalProvider";
import ChooseMaterialModal from "components/upload-material/ChooseMaterialModal";
import { iMutationMaterialData } from "interfaces/materialData";

interface MutationsWindowProps {
  mutations: iTeachingUnit[] | undefined;
  parent: iMutationMaterialData;
}

const MutationsWindow = ({mutations, parent}: MutationsWindowProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const { showModal } = useModal();

  return (
    <section className="mutations-window">
      {userInfo.id &&
        <PillButton color="primary" size="medium" className="add-new-mutation-button" onClick={() => showModal(<ChooseMaterialModal parent={parent}/>)}>+ Nahrát novou mutaci</PillButton>
      }
      {mutations && mutations.length === 0 && <p className="font-16-b no-mutations">Tato výuka zatím nemá žádné mutace</p>}
      <div className="mutations">
        {mutations && mutations.map((mutation: any, index) => (
          <RecommendedUnit key={index} {...mutation}/>
        ))}
      </div>
    </section>
  )
};

export default MutationsWindow;