import './Reviews.scss';
import ProfileBadge from "../../../profile-badge/ProfileBadge";
import { ReactComponent as StarIcon } from '../../../../images/star.svg';
import { ReactComponent as PlusIcon } from '../../../../images/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../../images/minus-icon.svg';
import { iReviewData } from '../../../../interfaces/review';
import { ReactComponent as EditIcon } from '../../../../images/edit-icon.svg';
import { ReactComponent as RemoveIcon } from '../../../../images/remove-icon.svg';
import { useModal } from '../../../../contexts/ModalProvider';
import RatingModal from '../rating-modal/RatingModal';
import GeneralModal from '../../../general-modals/GeneralModal';
import axios from 'utils/axios';
import { useDispatch } from 'react-redux';
import { setReloadTeachingUnit } from 'redux/utilsSlice';
import { useSnackbar } from 'contexts/SnackbarProvider';

const Reviews = ({ idOfTeachingUnit, author, creation_date, rating, _comments, editable, id }: iReviewData & {editable: boolean}) => {
  const {showModal, closeModal} = useModal();
  const dispatch = useDispatch();
  const { openSnackbar, openErrorSnackbar } = useSnackbar();

  const removeReview = () => {
    closeModal();
    axios.delete(`/api/ratings/${id}/delete`)
    .then(() => {
      dispatch(setReloadTeachingUnit("ratings"));
      openSnackbar('Hodnocení bylo úspěšně smazáno!');
    })
    .catch((error) => {
      openErrorSnackbar('Hodnocení se nepodařilo smazat!');
      console.error(error.response);
    });
  }

  return (
    <section className="reviews">
      <div className="review-container font-14">
        <div className="review-info mb-2">
          <div className='review-profile mb-2'>
            <ProfileBadge userId={author} name />
            <div className="review-date">
              {` (${new Date(creation_date).toLocaleString("cs-CZ", {day: "numeric",month: "short",year: "numeric"}).replace(",", "")})`}
            </div>
            {editable &&
              <div className='icons'>
                <EditIcon className='edit-icon s-green-bg-h' onClick={() => showModal(<RatingModal id={idOfTeachingUnit} rating={rating} comments={_comments} idOfRating={id}/>)}/>
                <RemoveIcon className='remove-icon l-red-bg-h' onClick={() => showModal(<GeneralModal text={"Opravdu chcete smazat hodnocení?"} actionOnClick={removeReview}/>)}/>
              </div>
            }
          </div>
          <div className="second-row">
            <div className="review-rating">
              {Array.from(Array(5).keys()).map((i) => (
                <StarIcon
                  key={i}
                  className={`star-icon ${i < rating ? '' : 'empty-star-icon'}`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pros-cons-container">
          <div className="review-comments-container">
            {_comments.map((comment, index) => {
              if (comment.is_positive) {
                return (
                  <div className="review-comment" key={index}>
                    <PlusIcon className='rating-icon'/>
                    <div className='ml-1'>{comment.text}</div>
                  </div>
                )
              }
            })}
          </div>
          <div className="review-comments-container">
            {_comments.map((comment, index) => {
              if (!comment.is_positive) {
                return (
                  <div className="review-comment" key={index}>
                    <MinusIcon className='rating-icon'/>
                    <div className='ml-1'>{comment.text}</div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reviews;