import { Category } from "interfaces/categories";

export const findCategoryById = (categories: Category[], id: number): Category[] => {
  for (const category of categories) {

    if (category.id === id) {
      return category.categories || [category];
    }

    if (category.categories) {
      const foundSubcategory = findCategoryById(category.categories, id);
      if (foundSubcategory.length > 0) {
        return foundSubcategory;
      }
    }
  }

  return [];
}

export const findSpecificCategory = (categories: Category[], id: number): Category[] => {
  const foundCategories: Category[] = [];

  for (const category of categories) {
    if (id === category.id) {
      foundCategories.push(category);
    }
    if (category.categories) {
      findSpecificCategory(category.categories, id);
    }
  }

  return foundCategories;
}

export const findParentIds = (categories: Category[], targetId: number): number[] => {
  const path: number[] = [];

  const searchCategories = (innerCategories: Category[], currentPath: number[]): boolean => {
      for (const category of innerCategories) {
          const newPath = [...currentPath, category.id];
          if (category.id === targetId) {
              path.push(...newPath);
              return true;
          }
          if (category.categories && searchCategories(category.categories, newPath)) {
              return true;
          }
      }
      return false;
  }

  searchCategories(categories, []);
  return path;
}

