import './Mutation.scss';
import { ReactComponent as StarIcon } from '../../images/star.svg';
import ArrowButton from '../buttons/ArrowButton';
import { iMutations } from '../../interfaces/mutations';
import ProfileBadge from "../profile-badge/ProfileBadge";

const Mutation = ({ id, author, number_of_ratings, number_of_downloads, average_rating }: iMutations) => {

	return (
		<div className='mutation font-14'>
			<div className='profilebadge'>
				<ProfileBadge userId={author} name />
			</div>
			<div className='mutation-stats'>
				<div className='mutation-rating'>
					{number_of_ratings}x / {average_rating ? (Math.round(average_rating * 10) / 10).toString().replace(".", ",") : 0} <StarIcon className='ml-1' />
				</div>
				<div className='downloads-number-container'>{number_of_downloads} stažení</div>
				<div className='mutation-arrow-button'>
					<ArrowButton id={id}/>
				</div>
			</div>
		</div>
	);
};

export default Mutation