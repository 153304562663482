import { useEffect, useState } from "react";
import "./DescriptionWindow.scss";

interface DescriptionWindowProps {
  description: string | undefined;
}

const DescriptionWindow = ({description}: DescriptionWindowProps) => {
  const [formattedDescription, setFormattedDescription] = useState<string>("");

  useEffect(() => {
    formatDescription();
  }, [description])

  const linkify = (text: string) => {
    const urlRegex = /(\b((https?|ftp|file):\/\/|www\.)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
      return `<a href="${url.match(/^https?:/) ? url : '//' + url}" target="_blank" rel="noreferrer">${url}</a>`;
    });
  }

  const formatDescription = () => {
    if (!description) return;
    let newDescription: string | null = description;
    if (!newDescription.startsWith("<p>")) newDescription = "<p>" + newDescription;
    if (!newDescription.endsWith("</p>/n")) newDescription = newDescription + "</p>";
    setFormattedDescription(linkify(newDescription.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '').replace(/<p>\s?<\/p>/g, '<br>').replace(/<em>/g, '<i>').replace(/<\/em>/g, '</i>').replace(/\\"/g, '"').replace(/\\n/g, '')));
  };

  return (
    <section className="description-window">
      <div className="description" dangerouslySetInnerHTML={{__html: formattedDescription}}/>
    </section>
  )
};

export default DescriptionWindow;