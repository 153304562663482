import { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal as closeReduxModal } from '../redux/modalSlice';
import UniversalModal from '../components/universal-modal/UniversalModal';
import { RootState } from 'redux/store';

interface ModalContextType {
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
  modalOpen: boolean;
}
export interface ModalProps {
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const reduxModalState = useSelector((state: RootState) => state.modal);

  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = modalOpen ? 'hidden' : 'initial'
  }, [modalOpen])

  useEffect(() => {
    if (reduxModalState.showModal) {
      setModalContent(reduxModalState.component);
      setModalOpen(true);
    } else {
      setModalContent(null);
      setModalOpen(false);
    }
  }, [reduxModalState]);

  const showModal = (content: ReactNode) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setModalOpen(false);
    dispatch(closeReduxModal()); // Sync with Redux
  };

  const contextValue: ModalContextType = {
    showModal,
    closeModal,
    modalOpen,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modalOpen && modalContent && <UniversalModal>{modalContent}</UniversalModal>}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const currentContext = useContext(ModalContext);

  if (!currentContext) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return currentContext;
};
