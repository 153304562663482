import { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import ProfileBadge from "../components/profile-badge/ProfileBadge";
import Keyword from "../components/keyword/Keyword";
import HeartButton from "../components/buttons/HeartButton";
import Rating from "../components/rating/Rating";
import CertifiedBadge from "../components/certified-badge/CertifiedBadge";
import axios from "../utils/axios";
import "./TeachingUnitPage.scss";
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../contexts/SnackbarProvider';
import DescriptionWindow from "../components/teaching-unit-menus/description-window/DescriptionWindow";
import PreviewWindow from "../components/teaching-unit-menus/preview-window/PreviewWindow";
import GuidesAdviceWindow from "../components/teaching-unit-menus/guides-advice-window/GuidesAdviceWindow";
import RatingsWindow from "../components/teaching-unit-menus/ratings-window/RatingsWindow";
import BestMutations from "../components/mutation/BestMutations";
import { getCachedKeyword } from "../utils/getCachedKeyword";
import { iTeachingUnit } from "../interfaces/teachingUnit";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import Button from "../components/buttons/Button";
import CurriculumsWindow from "../components/teaching-unit-menus/curriculums-window/CurriculumsWindow";
import AlternativesWindow from "../components/teaching-unit-menus/alternatives-window/AlternativesWindow";
import { setReloadTeachingUnit } from "../redux/utilsSlice";
import { iFavoriteLists } from "interfaces/favoriteLists";
import { useAuth } from "../contexts/AuthProvider";
import { ReactComponent as EditIcon } from '../images/edit-icon.svg';
import { ReactComponent as RemoveIcon } from '../images/remove-icon.svg';
import { useModal } from '../contexts/ModalProvider';
import GeneralModal from '../components/general-modals/GeneralModal';
import UploadMaterialModal from "../components/upload-material/UploadMaterialModal";
import MutationsWindow from "components/teaching-unit-menus/mutations-window/MutationsWindow";
import Footer from "components/footer/Footer";
import BreadcrumbNavTeachingUnit from "components/breadcrumb-nav/BreadcrumbNavTeachingUnit";
import { getSpecificCategory } from "utils/getCachedCategories";

const TeachingUnitPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [teachingUnit, setTeachingUnit] = useState<iTeachingUnit | null>(null);
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const reloadTeachingUnit = useSelector((state: any) => state.utils.reloadTeachingUnit);
  const [numberOfAlternatives, setNumberOfAlternatives] = useState<number>(0);
  const [numberOfMutations, setNumberOfMutations] = useState<number>(0);
  const { openSnackbar, openErrorSnackbar } = useSnackbar();
  const { showModal, closeModal } = useModal();
  const [activeMenu, setActiveMenu] = useState<JSX.Element>(<DescriptionWindow description={teachingUnit?.description}/>);
  const [subjectName, setSubjectName] = useState<string | null>(null);
  const [gradeName, setGradeName] = useState<string | null>(null);
  const [gradeTypeName, setGradeTypeName] = useState<string | null>(null);
  const [languageName, setLanguageName] = useState<string | null>(null);
  const [skipEffect, setSkipEffect] = useState<boolean>(false);
  const [menuMap, setMenuMap] = useState(new Map<string, JSX.Element>());
  const [cart, setCart] = useState<number[] | null>(null);
  const [parentAuthor, setParentAuthor] = useState<number>(0);
  const { checkIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    window.scrollTo({top: 0, left: 0});
    getTeachingUnitById(id);
    reloadLists();
  }, [id]);

  useEffect(() => {
    if (!teachingUnit) return;
    if (skipEffect) {
      setSkipEffect(false);
      return;
    }
    if (teachingUnit.subject) fetchKeyword("subjects", setSubjectName, teachingUnit.subject);
    if (teachingUnit.grade) fetchKeyword("grades", setGradeName, teachingUnit.grade);
    if (teachingUnit.grade_type) fetchKeyword("grade-types", setGradeTypeName, teachingUnit.grade_type);
    if (teachingUnit.language) fetchKeyword("languages", setLanguageName, teachingUnit.language);
    setActiveMenu(<DescriptionWindow description={teachingUnit?.description}/>);
    setNewMenus();
    if (teachingUnit.parent) getParentAuthor(teachingUnit.parent);
    if (reloadTeachingUnit) {
      if (reloadTeachingUnit === "alternatives") {
        setActiveMenu(<AlternativesWindow parentId={id} isAuthor={userInfo.id === teachingUnit.author} alternatives={teachingUnit._alternatives}/>);
      }
      if (reloadTeachingUnit === "ratings") {
        setActiveMenu(<RatingsWindow id={Number(id)}/>);
      }
      if (reloadTeachingUnit === "guidesAdvice") {
        setActiveMenu(<GuidesAdviceWindow isAuthor={teachingUnit.author === userInfo.id} id={id} guideLinks={teachingUnit._guide_urls} adviceLinks={teachingUnit._advice_urls}/>);
      }
      dispatch(setReloadTeachingUnit(""));
    } else {
      setActiveMenu(<DescriptionWindow description={teachingUnit?.description}/>);
    }
  }, [teachingUnit]);

  useEffect(() => {
    if (!id || !reloadTeachingUnit) return;
    getTeachingUnitById(id);
  }, [reloadTeachingUnit]);

  const setNewMenus = () => {
    if (!teachingUnit) return;
    const newMenuMap = new Map<string, JSX.Element>();
    newMenuMap.set("Popis", <DescriptionWindow description={teachingUnit?.description}/>);
    newMenuMap.set("Náhled plánu", <PreviewWindow id={id}/>);
    newMenuMap.set("Hodnocení", <RatingsWindow id={Number(id)}/>);
    newMenuMap.set("Výstupy RVP", <CurriculumsWindow id={id}/>);
    newMenuMap.set("Poradna a návody", <GuidesAdviceWindow isAuthor={teachingUnit.author === userInfo.id} id={id} guideLinks={teachingUnit._guide_urls} adviceLinks={teachingUnit._advice_urls}/>);
    newMenuMap.set("Alternativy (" + numberOfAlternatives + ")", <AlternativesWindow parentId={id} isAuthor={userInfo.id === teachingUnit.author} alternatives={teachingUnit._alternatives}/>);
    if (!teachingUnit.parent) {
      newMenuMap.set("Mutace (" + numberOfMutations + ")", 
      <MutationsWindow mutations={teachingUnit._mutations} parent={{
        id: String(teachingUnit.id), 
        name: teachingUnit.name,
        lessonCount: teachingUnit.number_of_lessons, 
        description: teachingUnit.description, 
        subject: teachingUnit.subject, 
        gradeType: teachingUnit.grade_type, 
        grade: teachingUnit.grade,
        category: teachingUnit.category,
        language: teachingUnit.language,
        keywords: teachingUnit._tags.map((keyword) => keyword.name)
        }}
      />
    );
    }
    setMenuMap(newMenuMap);
  }

  const getTeachingUnitById = async (id: string) => {
    try {
      const response = await axios.get(`/api/teaching-units/${id}`);
      setNumberOfAlternatives(response.data._alternatives?.length);
      setNumberOfMutations(response.data.mutations?.length);
      //console.info(response.data);
      setTeachingUnit(response.data);
    } catch (error) {
      console.error("Error fetching teaching unit by ID:", error);
    }
  };

  const getParentAuthor = async (id: number) => {
    try {
      const response = await axios.get(`/api/teaching-units/${id}`);
      if (response.data.author) setParentAuthor(response.data.author);
      else setParentAuthor(0);
    } catch (error) {
      console.error("Error fetching teaching unit by ID:", error);
    }
  }

  const reloadLists = async () => {
    if (userInfo.main_list === undefined) return;
    const response = await axios.get("/api/lists");
    const cart = response.data.filter((list: iFavoriteLists) => list.is_main)[0];
    if (cart === undefined) return;
    setCart(cart.teaching_units);
  };

  const updateCart = () => {
    checkIsLoggedIn(Boolean(userInfo.id));
    if (id === undefined || cart === null) return;
    let newCart: number[] = cart;
    let remove: boolean = false;
    if (newCart.includes(Number(id))) {
      newCart = newCart.filter((item) => item !== Number(id));
      remove = true;
    }
    else newCart.push(Number(id));
    handleAddToCart(newCart, remove);
  }

  const handleAddToCart = async (newCart: number[], remove: boolean) => {
    await axios.put(`/api/lists/${userInfo.main_list}`, {"teaching_units": newCart})
      .then(() => {
        if (remove) openSnackbar("Výukový celek odebrán z košíku");
        else openSnackbar("Výukový celek přidán do košíku");
        reloadLists();
      })
      .catch((error) => {
        openErrorSnackbar("Nepadřilo se přidat výukový celek do košíku");
        console.error("Error adding teaching unit to cart:", error);
      });
  };

  const fetchKeyword = async (type: string, setter: React.Dispatch<React.SetStateAction<string | null>>, value: number) => {
    const name = await getCachedKeyword(value, type);
    setter(name);
  };
  
  if (!teachingUnit) return (
    <div className="teaching-unit-page">
      <Navbar/>
      <LoadingScreen/>
    </div>
  );

  const handleCertificationToggle = async () => {
    try {
      const response = await axios.put(`/api/teaching-units/${id}/update/admin`, {
        certificated: !teachingUnit.certificated
      });
      if (response.status === 200) {
        setSkipEffect(true);
        setTeachingUnit({ ...teachingUnit, certificated: !teachingUnit.certificated });
      }
    } catch (error) {
      console.error("Error updating certification status:", error);
    }
  };
  
  const handleRecommendationToggle = async () => {
    try {
      const response = await axios.put(`/api/teaching-units/${id}/update/admin`, {
        is_recommended: !teachingUnit.is_recommended
      });
      if (response.status === 200) {
        setSkipEffect(true);
        setTeachingUnit({ ...teachingUnit, is_recommended: !teachingUnit.is_recommended });
      }
    } catch (error) {
      console.error("Error updating recommendation status:", error);
    }
  };

  const openEditModal = async () => {
    const loadedSubject = await getCachedKeyword(teachingUnit.subject, "subjects", true);
    const loadedGradeType = await getCachedKeyword(teachingUnit.grade_type, "grade-types", true);
    const loadedGrade = await getCachedKeyword(teachingUnit.grade, "grades", true);
    const loadedLanguage = await getCachedKeyword(teachingUnit.language, "languages", true);
    const loadedCategory = await getSpecificCategory(teachingUnit.category);
    const loadedTags: string[] = [];
    teachingUnit._tags.forEach((tag) => {loadedTags.push(tag.name)});
    const loadedCurriculums: string[] = [];
    const response = await axios.get(`/api/teaching-units/${id}/curriculums`);
    response.data.forEach((curriculum: any) => {loadedCurriculums.push(curriculum.name)}); 
    const newMaterialData = {
      id: id,
      parent: teachingUnit.parent ? teachingUnit.parent : undefined,
      name: teachingUnit.name,
      lessonCount: teachingUnit.number_of_lessons,
      description: teachingUnit.description,
      subject: loadedSubject,
      gradeType: loadedGradeType,
      grade: loadedGrade,
      category: loadedCategory,
      language: loadedLanguage,
      curriculums: loadedCurriculums,
      keywords: loadedTags
    };
    showModal(<UploadMaterialModal loadedMaterialData={newMaterialData}/>);
  }

  const removeTeachingUnit = () => {
    closeModal();
    axios.delete(`/api/teaching-units/${id}/delete`)
    .then(() => {
      openSnackbar('Výukový celek byl úspěšně smazán!');
      navigate("/");
    })
    .catch((error) => {
      openErrorSnackbar('Výukový celek se nepodařilo smazat!');
      console.error(error.response);
    });
  }

  return (
    <>
      <div className="teaching-unit-page">
        <Navbar />
        <div className="content">
          <BreadcrumbNavTeachingUnit subject={teachingUnit.subject} category={teachingUnit.category} teachingUnit={teachingUnit}/>
          <div className="top-row">
            <div className="left-column">
              <div className="unit-title-section">
                <h3 className="unit-title p-blue font-20-b">
                  <span>{teachingUnit.name} - set {teachingUnit.number_of_lessons} hodin</span>
                </h3>
                <div className="unit-title-buttons">
                  <HeartButton teachingUnitId={Number(id)} teachingUnitName={teachingUnit.name} reloadLists={reloadLists}/>
                  {(userInfo.is_staff || userInfo.id === teachingUnit.author) &&
                    <>
                      <EditIcon className='edit-icon s-green-bg-h' onClick={() => openEditModal()}/>
                      <RemoveIcon className='remove-icon l-red-bg-h' onClick={() => showModal(<GeneralModal text={"Opravdu chcete smazat výukový celek?"} actionOnClick={removeTeachingUnit}/>)}/>
                    </>
                  }
                </div>
              </div>
              <div className="unit-author font-14">
                {teachingUnit.parent ?
                  <>
                    {parentAuthor !== 0 ?
                      <>
                        <span>Autor: </span>
                        <ProfileBadge userId={parentAuthor} name={true} />
                      </>
                    :
                      <span>Rodičovský výukový celek byl smazán</span>
                    }
                  </>
                :
                  <>
                    <span>Autor: </span>
                    <ProfileBadge userId={teachingUnit.author} name={true} />
                  </>
                }
              </div>
              {teachingUnit.parent && (
                <div className="unit-author font-14">
                  <span>Autor této mutace:</span>
                  <ProfileBadge userId={teachingUnit.author} name={true} />
                </div>
              )}
              <ul className="unit-keywords">
                {subjectName && <Keyword key={subjectName} keyword={subjectName}/>}
                {gradeTypeName && <Keyword key={gradeTypeName} keyword={gradeTypeName}/>}
                {gradeName && <Keyword key={gradeName} keyword={gradeName}/>}
                {teachingUnit._tags && teachingUnit._tags.map((keyword, index) => <Keyword key={index} keyword={keyword.name} />)}
              </ul>
              <div className="language-container">
                <span className="font-14">Jazyk: {languageName}</span>
              </div>
              <div className="unit-controls">
                <span className="font-14 inline-block">{teachingUnit.number_of_ratings} hodnocení</span>
                <span className="font-14 inline-block downloads">{teachingUnit.number_of_downloads} stažení</span>
                <div className="inline-block ratings"><Rating rating={teachingUnit.average_rating ? Math.round(teachingUnit.average_rating * 10) / 10 : 0} /></div>
                {teachingUnit.certificated && <div className="inline-block certified"><CertifiedBadge text={true} large={false}/></div>}
              </div>
            </div>
            <div className="middle-column">
              {cart?.includes(Number(id)) ? 
                <Button className="from-cart-button" size={"large"} color={"primary"} onClick={updateCart}>Z košíku</Button>
              :
                <Button className="cart-button" size={"large"} color={"primary"} onClick={updateCart}>Do košíku</Button>
              }
              {userInfo.is_staff && (
                <div>
                  <button className="remove-certification font-14-b p-purple-h" onClick={handleCertificationToggle}>
                    {teachingUnit.certificated ? "Odebrat certifikaci" : "Certifikovat"}
                  </button>
                  <button className="remove-recommendation font-14-b p-blue-h" onClick={handleRecommendationToggle}>
                    {teachingUnit.is_recommended ? "Nedoporučovat" : "Doporučovat"}
                  </button>
                </div>
              )}
            </div>
            <div className="right-column">
              {teachingUnit._mutations.length > 0 && 
                <BestMutations 
                  mutations={teachingUnit._mutations.slice(0, 3)}
                  parent={{
                    id: String(teachingUnit.id), 
                    name: teachingUnit.name,
                    lessonCount: teachingUnit.number_of_lessons, 
                    description: teachingUnit.description, 
                    subject: teachingUnit.subject, 
                    gradeType: teachingUnit.grade_type, 
                    grade: teachingUnit.grade,
                    category: teachingUnit.category,
                    language: teachingUnit.language,
                    keywords: teachingUnit._tags.map((keyword) => keyword.name)
                    }}
                />
              }
            </div>
          </div>
          <div className="detail-content">
            <div className="detail-navbar">
              {Array.from(menuMap).map(([value, key]:any[]) => (
                <button className={`menu-item l-blue-bg font-16 ${activeMenu.type === key.type ? 'active' : ''}`}  key={value} onClick={() => setActiveMenu(key)}>{value}</button>
              ))}
            </div>
            {activeMenu}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default TeachingUnitPage;