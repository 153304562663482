import { SVGProps, ReactNode } from "react";
import './SettingsField.scss'

interface SettingsFieldProps {
  label: string;
  value?: string;
  Icon: ReactNode | React.ComponentType<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}

const SettingsField = ({ label, value, Icon, onClick }: SettingsFieldProps) => {
  return (
    <div className={`${onClick === undefined ? "settings-field" : "settings-field-hoverable"}`}>
      <button className="bg-white shadow-md border-[#D7EBFF] p-3 rounded-md w-full" disabled={onClick === undefined} onClick={onClick}>
        <div className="flex-grow text-left flex justify-stretch overflow-hidden">
          <div className="p-1 flex items-center">
            {typeof Icon === 'function' ? <Icon className="mr-2 h-12 w-12" /> : Icon}
          </div>
          {value ?
            <div className="flex flex-col justify-between p-1 overflow-hidden">
              <div className="font-14 p-blue">{label}</div>
              <div className="font-16-b p-blue text-ellipsis overflow-hidden">{value}</div>
            </div>
            :
            <div className="p-1 flex flex-col justify-center">
              <div className="font-16-b p-blue">{label}</div>
            </div>
          }
        </div>
      </button>
    </div>
  );
};

export default SettingsField;
