import axios from '../../utils/axios';
import { List } from "../../pages/ListPage";
import { useModal } from "../../contexts/ModalProvider";
import "./Modals.scss"
import Button from '../buttons/Button';

const RemoveList = ({ list, refreshLists }: { list: List, refreshLists: (first?: boolean) => void }) => {  
  const { closeModal } = useModal();

  const handleRemoveList = async () => {
    await axios.delete(`/api/lists/${list.id}`);
    refreshLists(true); 
    closeModal();
  };

  return (
    <div className='remove-list'>
      <div className="header-wrapper">
        <h2 className="font-16-b">Opravdu chcete odstranit seznam {list.name}?</h2>
      </div>
      <Button size="medium" onClick={handleRemoveList}>Ano, odstranit</Button>
    </div>
  );
};

export default RemoveList;