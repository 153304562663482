import { KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "../../../utils/axios";
import { passwordSchema, confirmPasswordSchema } from '../../../utils/validationSchemas';
import * as yup from 'yup';
import { useModal } from "../../../contexts/ModalProvider";
import { useSnackbar } from "../../../contexts/SnackbarProvider";
import "./PasswordModal.scss";
import Button from '../../buttons/Button';

type Form = {
  oldPassword: string;
  password: string;
  confirmPwd: string;
};

const PasswordModal = () => {
  const { closeModal } = useModal();
  const { openSnackbar } = useSnackbar();
  const [oldPasswordError, setOldPasswordError] = useState<string>('');

  const formSchema = yup.object().shape({
    oldPassword: passwordSchema,
    password: passwordSchema,
    confirmPwd: confirmPasswordSchema
  });

  const { register, handleSubmit, formState: { errors } } = useForm<Form>({
    resolver: yupResolver(formSchema)
  });

  const updatePassword = async (data: Form) => {
    try {
      const response = await axios.post("/api/users/user/change-password", {
        old_password: data.oldPassword,
        new_password: data.password,
        new_password_again: data.confirmPwd,
      });

      if (response.status === 200) {
        closeModal();
        openSnackbar("Heslo bylo úspěšně změněno.");
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        setOldPasswordError(error.response.data.cz);
      }
    }
  };

  return (
    <div className="password-modal" onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter') handleSubmit(updatePassword)() }}>
      <h2 className="font-20-b mb-4 select-none">Změnit heslo</h2>
      <form onSubmit={handleSubmit(updatePassword)}>
        <div>
          <label className="font-14 uppercase select-none">Původní heslo:</label>
          <input
            className={`mt-1 p-2 w-full border rounded-md ${errors.oldPassword || oldPasswordError ? "border-red-600" : ""}`}
            type="password"
            {...register("oldPassword")}
          />
          {oldPasswordError ?
            <p className={`${oldPasswordError ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{oldPasswordError}!</p> 
            : 
            <p className={`${errors.oldPassword ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.oldPassword?.message}!</p>
          }
        </div>
        <div>
          <label className="font-14 uppercase select-none">Nové heslo:</label>
          <input
            className={`mt-1 p-2 w-full border rounded-md ${errors.password ? "border-red-600" : ""}`}
            type="password"
            {...register("password")}
          />
          <p className={`${errors.password ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.password?.message}!</p>
        </div>
        <div>
          <label className="font-14 uppercase select-none">Potvrdit nové heslo:</label>
          <input
            className={`mt-1 p-2 w-full border rounded-md ${errors.confirmPwd ? "border-red-600" : ""}`}
            type="password"
            {...register("confirmPwd")}
          />
          <p className={`${errors.confirmPwd ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.confirmPwd?.message}!</p>
        </div>
        <div className="w-full flex justify-end">
          <Button size='medium'>Potvrdit</Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordModal;
