import axios from "./axios";



export const getCachedKeyword = async (keywordId: number, type: string, fullObject = false) => {
  const cachedObject = sessionStorage.getItem(type);
  if (cachedObject) {
    const objects = JSON.parse(cachedObject);
    const object = objects.find((subjectObject: any) => subjectObject.id === keywordId);
    if (object && object.name) {
      if (fullObject) return object;
      return object.name;
    } 
  }
  
  const response = await axios.get("/api/" + type);
  const loadedObject = response.data;
  if (type === "subjects") loadedObject.sort((a: any, b: any) => a.id - b.id);
  sessionStorage.setItem(type, JSON.stringify(loadedObject));
  const object = loadedObject.find((subjectObject: any) => subjectObject.id === keywordId);
  if (object && object.name) {
    if (fullObject) return object;
    return object.name;
  }

  return "";
};

export const getCachedObjects = async (type: string) => {
  const cachedObject = sessionStorage.getItem(type);
  if (cachedObject) {
    const objects = JSON.parse(cachedObject);
    if (objects) {
      return objects;
    } 
  }
  
  const response = await axios.get("/api/" + type);
  const loadedObject = response.data;
  if (type === "subjects" || type === "categories/subjects/leaves") loadedObject.sort((a: any, b: any) => a.id - b.id);
  sessionStorage.setItem(type, JSON.stringify(loadedObject));
  if (loadedObject) {
    return loadedObject;
  }

  return [];
};