import './RatingModal.scss'
import { useEffect, useState } from 'react';
import { ReactComponent as StarIcon } from '../../../../images/star.svg';
import { ReactComponent as PlusIcon } from '../../../../images/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../../images/minus-icon.svg';
import Button from '../../../buttons/Button';
import axios from '../../../../utils/axios';
import { useDispatch } from 'react-redux';
import { setReloadTeachingUnit } from '../../../../redux/utilsSlice';
import { useModal } from '../../../../contexts/ModalProvider';
import { useSnackbar } from '../../../../contexts/SnackbarProvider';
import { iComment, iRequestReviewData } from '../../../../interfaces/review';

interface RatingModalProps {
  id: number;
}

type RatingModalState = {
  idOfRating?: number;
  rating?: number;
  comments?: iComment[];
}

const RatingModal = ({id, idOfRating = undefined, rating = undefined, comments = undefined}: RatingModalProps & RatingModalState) => {
  const starArray: number[] = [5, 4, 3, 2, 1];
  const [selectedRating, setSelectedRating] = useState<number>(0);
  const [pros, setPros] = useState<string[]>(['']); 
  const [cons, setCons] = useState<string[]>(['']);
  const [isProRemoveIconVisible, setIsProRemoveIconVisible] = useState<number | undefined>(undefined);
  const [isConRemoveIconVisible, setIsConRemoveIconVisible] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const { openSnackbar, openErrorSnackbar } = useSnackbar();
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (rating === undefined) return;
    setEditing(true);
    setSelectedRating(rating);
    if (comments === undefined) return;
    const newPros: string[] = [];
    const newCons: string[] = [];
    comments.forEach(comment => {
      if (comment.is_positive) {
        newPros.push(comment.text);
      } else {
        newCons.push(comment.text);
      }
    });
    setPros(newPros);
    setCons(newCons);
  }, [])

  const add = (list: string[], setter:  React.Dispatch<React.SetStateAction<string[]>>) => {
    if (list.length > 15) return;
    setter([...list, '']);
  }

  const update = (index: number, value: string, list: string[], setter:  React.Dispatch<React.SetStateAction<string[]>>) => {
    const newList: string[] = [...list];
    newList[index] = value;
    setter(newList);
  }

  const remove = (index: number, list: string[], setter:  React.Dispatch<React.SetStateAction<string[]>>) => {
    const newList: string[] = [...list];
    newList.splice(index, 1);
    setter(newList);
  }

  const autoheight = (el: HTMLTextAreaElement) => {
    el.style.height = '1px';
    el.style.height = `${el.scrollHeight}px`;
  }

  const submit = () => {
    const filteredPros = pros.filter((pro) => pro.trim() !== '');
    const filteredCons = cons.filter((cons) => cons.trim() !== '');
    if (selectedRating === 0) {
      openErrorSnackbar('Vyberte prosím hodnocení!');
      return;
    }
    const request: iRequestReviewData = {
      rating: selectedRating,
      positive_comments: filteredPros,
      negative_comments: filteredCons
    }
    if (editing) {
      request.id = idOfRating;
      updateRating(request);
    } else {
      submitRating(request);
    }
  }

  const submitRating = (request: iRequestReviewData) => {
    axios.post(`/api/teaching-units/${id}/ratings`, request)
      .then(() => {
        dispatch(setReloadTeachingUnit("ratings"));
        openSnackbar('Hodnocení bylo úspěšně přidáno!');
        closeModal();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          openErrorSnackbar(error.response.data.cz);
        } else {
          console.error(error.response);
        }
      })
  };

  const updateRating = (request: iRequestReviewData) => {
    axios.put(`/api/ratings/${idOfRating}/update`, request)
    .then(() => {
      dispatch(setReloadTeachingUnit("ratings"));
      openSnackbar('Hodnocení bylo úspěšně upraveno!');
      closeModal();
    })
    .catch((error) => {
      openErrorSnackbar("Hodnocení se nezdařilo upravit!");
      console.error(error.response);
    })
  }

  return (
    <div className='rating-modal'>
      <div className="modal-header px-1 py-1">
        {editing ?
          <h3 className='font-20-b'>Upravit hodnocení</h3>
        :
          <h3 className='font-20-b'>Přidat hodnocení</h3>
        }
        
      </div>
      <div className="modal-body">
        <div className="star-input mt-4">
          {starArray.map((starNumber) => {
            return (
              <button
                type='button'
                key={starNumber}
                onClick={() => setSelectedRating(starNumber)}>
                  <StarIcon className={`star-icon ${starNumber > selectedRating ? 'selected' : ''} `} />
              </button>
            );
          })}
        </div>
        <div className='modal-pros-cons-container mt-10'>
          <div className='input-container'>
            <span className='inline-flex'>
              <PlusIcon/>
              <h3 className='font-14-b ml-1'>Plusy:</h3>
            </span>
            <div className='pros'>
            {pros.map((pro, index) => (
              <div className='input-item mt-2' key={index} 
                onMouseEnter={() => setIsProRemoveIconVisible(index)}
                onMouseLeave={() => setIsProRemoveIconVisible(undefined)}>
                <div className='wrapper'>
                  <textarea
                    rows={1}
                    value={pro}
                    spellCheck={false}
                    maxLength={255}
                    onKeyPress={e => { if(e.key === 'Enter') e.preventDefault()}}
                    onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => autoheight(e.target)}
                    onChange={(e) => update(index, e.target.value, pros, setPros)}
                  />
                  {isProRemoveIconVisible === index && (<button className='remove-input' onClick={() => remove(index, pros, setPros)}><MinusIcon/></button>)}
                </div>
                <div className='divider'/>
              </div>
            ))}
              <button className='add-input' onClick={() => add(pros, setPros)}><PlusIcon/></button>
            </div>
          </div>
          <div className='input-container'>
            <span className='inline-flex'>
              <MinusIcon/>
              <h3 className='font-14-b ml-1'>Mínusy:</h3>
            </span>
            <div className='cons'>
            {cons.map((con, index) => (
              <div className='input-item mt-2' key={index}
                onMouseEnter={() => setIsConRemoveIconVisible(index)}
                onMouseLeave={() => setIsConRemoveIconVisible(undefined)}>
                  <div className='wrapper'>
                    <textarea
                      rows={1}
                      value={con}
                      spellCheck={false}
                      maxLength={255}
                      onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => autoheight(e.target)}
                      onChange={(e) => update(index, e.target.value, cons, setCons)}
                    />
                    {isConRemoveIconVisible === index && (<button className='remove-input' onClick={() => remove(index, cons, setCons)}><MinusIcon/></button>)}
                  </div>
                  <div className='divider'/>
                </div>
              ))}
              <button className='add-input' onClick={() => add(cons, setCons)}><PlusIcon/></button>
            </div>
          </div>
        </div>
      </div>
      {editing ?
        <Button className='submit-button' size={"small"} color={"primary"} onClick={submit}>Upravit hodnocení</Button>
      :
        <Button className='submit-button' size={"small"} color={"primary"} onClick={submit}>Přidat hodnocení</Button>
      }
    </div>
  )

}

export default RatingModal;