import React, { useState, useEffect, useLayoutEffect } from "react";
import './BreadcrumbNav.scss';

import {selectedSubjectIds, selectedCategoryIds, subjects, activeFilters} from 'components/filter-logic/useFilterLogic';
import { Category } from "interfaces/categories";
import { useNavigate } from "react-router-dom";
import {categoriesFiltered, categoriesParamsLoaded} from 'pages/SearchPage';
import { findSpecificCategory, findCategoryById, findParentIds } from "utils/findCategoryById";

interface Breadcrumb {
  name: string;
  id: number;
}

type BreadcrumbProps = {
  categoriesData: Category[];
}

const BreadcrumbNav = ({categoriesData}: BreadcrumbProps) => {
  const cleanBreadcrumb: Breadcrumb[] = [
    { name: 'Home', id: 0 },
    { name: 'Plány výuky', id: 0 },
  ];
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(cleanBreadcrumb);
  const [breadcrumbsVisible, setBreadcrumbsVisible] = useState<boolean>(true);
  const [categoriesDataFiltered, setCategoriesDataFiltered] = useState<Category[]>([]);
  const [last, setLast] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    categoriesFiltered.value = categoriesDataFiltered;
  }, [categoriesDataFiltered])

  useEffect(() => {
    if (categoriesData.length === 0) return;
    setCategoriesDataFiltered(categoriesData);
    if (categoriesParamsLoaded.value) {
      const subject = subjects.value.find((subject) => subject.id === selectedSubjectIds.value[0]);
      if (subject) {
        setBreadcrumbs([...cleanBreadcrumb, {name: subject.name, id: subject.id}]);

        const selectedSubject = categoriesData.find(subject => subject.id === selectedSubjectIds.value[0]);
        if (!selectedSubject || !selectedSubject.categories) return;
        if (selectedSubject && selectedSubject.categories) {
          setCategoriesDataFiltered(selectedSubject.categories);
        } else {
          categoriesFiltered.value = categoriesData;
          setCategoriesDataFiltered(categoriesData);
        }

        const categoryIds = findParentIds(selectedSubject.categories, selectedCategoryIds.value[0]);

        let newCategoriesFiltered = selectedSubject.categories;
        for (let i = 0; i < categoryIds.length; i++) {
          const searchedCategory = findSpecificCategory(newCategoriesFiltered, categoryIds[i])[0];
          if (searchedCategory.categories) {
            newCategoriesFiltered = searchedCategory.categories;
            
          } else {
            setLast(true);
          }
          setBreadcrumbs((prev) => [...prev, {name: searchedCategory.name, id: searchedCategory.id}]);
        }
        if (newCategoriesFiltered) {
          setCategoriesDataFiltered(newCategoriesFiltered);
        }
        
        const category = newCategoriesFiltered.find((category) => category.id === selectedCategoryIds.value[0]);
        if (category) {
          let found = false;
          const newCategories = selectedSubject.categories.reduce((acc, category) => {
            if (category.id === selectedCategoryIds.value[0] && category.categories) {
              found = true;
              return [...category.categories];
            } else {
              return acc;
            }
          }, [] as Category[]);

          setCategoriesDataFiltered(found ? newCategories : newCategoriesFiltered);
        }
      }
      categoriesParamsLoaded.value = false;
    }
  }, [categoriesData])

  useLayoutEffect(() => {
    if (categoriesData.length === 0) return;
    if (selectedSubjectIds.value.length === 0) {
      setBreadcrumbs(cleanBreadcrumb);
      setBreadcrumbsVisible(true);
      setCategoriesDataFiltered(categoriesData);
      setLast(false);
    } else if (selectedSubjectIds.value.length > 1) {
      setBreadcrumbs(cleanBreadcrumb);
      setBreadcrumbsVisible(false);
      setCategoriesDataFiltered(categoriesData);
      setLast(false);
    } else {
      if (breadcrumbs.length === 2 || selectedCategoryIds.value.length === 0) {
        const subject = subjects.value.find((subject) => subject.id === selectedSubjectIds.value[0]);
        if (subject) {
          setBreadcrumbs([...cleanBreadcrumb, {name: subject.name, id: subject.id}]);
          setBreadcrumbsVisible(true);
          setLast(false);

          const selectedSubject = categoriesData.find(subject => subject.id === selectedSubjectIds.value[0]);
          if (selectedSubject && selectedSubject.categories) {
            categoriesFiltered.value = selectedSubject.categories;
            setCategoriesDataFiltered(selectedSubject.categories);
          } else {
            categoriesFiltered.value = categoriesData;
            setCategoriesDataFiltered(categoriesData);
          }
          
          return;
        }
      }
      const category = categoriesDataFiltered.find((category) => category.id === selectedCategoryIds.value[0]);
      if (category) {
        if ("categories" in categoriesDataFiltered[0]) {
          setBreadcrumbs((prev) => [...prev, {name: category.name, id: category.id}]);
        } else {
          setLast(true);
          if (last) setBreadcrumbs((prev) => [...prev.slice(0, -1), {name: category.name, id: category.id}]);
          else setBreadcrumbs((prev) => [...prev, {name: category.name, id: category.id}]);
        }
        setBreadcrumbsVisible(true);
        setCategoriesDataFiltered(prev => {
          let found = false;
          const newCategories = prev.reduce((acc, category) => {
            if (category.id === selectedCategoryIds.value[0] && category.categories) {
              found = true;
              return [...category.categories];
            } else {
              return acc;
            }
          }, [] as Category[]);
        
          return found ? newCategories : prev;
        });
        return;
      } else {
        const selectedSubject = categoriesData.find(subject => subject.id === selectedSubjectIds.value[0]);
        if (selectedSubject && selectedSubject.categories) {
          categoriesFiltered.value = findCategoryById(selectedSubject.categories, selectedCategoryIds.value[0]);
          setCategoriesDataFiltered(findCategoryById(selectedSubject.categories, selectedCategoryIds.value[0]));
          const index = breadcrumbs.slice(3).findIndex(item => item.id === selectedCategoryIds.value[0]);
          setBreadcrumbs([...breadcrumbs.slice(0, 3), ...breadcrumbs.slice(3, 4 + index)]);
          setLast(false);
        } else {
          categoriesFiltered.value = categoriesData;
          setCategoriesDataFiltered(categoriesData);
        }
      }
    }
  }, [selectedSubjectIds.value, selectedCategoryIds.value])

  const handleBreadcrumbClick = (id: number, index: number) => {
    if (index === 0) {
      navigate("/");
      return;
    }
    if (index === 1) {
      setBreadcrumbs(cleanBreadcrumb);
      setBreadcrumbsVisible(true);
      setCategoriesDataFiltered(categoriesData);
      categoriesFiltered.value = categoriesData;
      setLast(false);
      if (selectedSubjectIds.value.length === 1) {
        const subjectName = subjects.value.filter(subject => subject.id === selectedSubjectIds.value[0])[0].name;
        activeFilters.value = activeFilters.value.filter(item => item.name !== subjectName);
      }
      selectedSubjectIds.value = [];
      selectedCategoryIds.value = [];
      return;
    }
    if (index === 2) {
      setBreadcrumbs((prev) => [...prev.slice(0, 2)]);
    
      selectedCategoryIds.value = [];
      return;
    }
    selectedCategoryIds.value = [id];
  };

  if (!breadcrumbsVisible) return (
    <nav className="breadcrumb-nav">
      {cleanBreadcrumb.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <span className='item font-14' onClick={() => handleBreadcrumbClick(breadcrumb.id, index)}>
            {breadcrumb.name}
          </span>
          {index < cleanBreadcrumb.length - 1 && <span className="separator">{" > "}</span>}
        </React.Fragment>
      ))}
    </nav>
  );
  
  return (
    <nav className="breadcrumb-nav">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <span className='item font-14' onClick={() => handleBreadcrumbClick(breadcrumb.id, index)}>
            {breadcrumb.name}
          </span>
          {index < breadcrumbs.length - 1 && <span className="separator">{" > "}</span>}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default BreadcrumbNav;