import axios from '../../utils/axios';
import { useEffect, useLayoutEffect, useState } from 'react';
import "./SavedList.scss"
import { iFavoriteLists } from '../../interfaces/favoriteLists';
import { useModal } from '../../contexts/ModalProvider';
import Button from '../buttons/Button';
import LoadingScreen from '../loading-screen/LoadingScreen';
import { Link } from 'react-router-dom';

interface SavedListProps {
  teachingUnitName: string;  
  teachingUnitId: number;
  reloadLists?: () => void;
}

const SavedList = ({ teachingUnitName, teachingUnitId, reloadLists }: SavedListProps) => {
  const [checkedLists, setCheckedLists] = useState<number[]>([]); 
  const [favLists, setFavLists] = useState<iFavoriteLists[]>([]);
  const [newListName, setNewListName] = useState<string>("");
  const [showNewListInput, setShowNewListInput] = useState<boolean>(false);
  const { closeModal } = useModal();

  useLayoutEffect(() => {
    getLists();
  }, [])

  useEffect(() => {
    checkLists();
  }, [favLists]);

  const getLists = async () => {
    try {
      const response = await axios.get("/api/lists");
      const lists = response.data.sort((a: any, b: any) => a.id - b.id);
      const mainList = lists.find((list: any) => list.is_main);
      const newFavLists = [...lists];
      if (mainList) {   
        newFavLists.splice(lists.indexOf(mainList), 1);
        newFavLists.unshift(mainList);
      }
      setFavLists(newFavLists)
    } catch (error) {
      console.error("Error displaying saved lists:", error);
    }
  }

  const checkLists = () => {
    const newList: number[] = [];
    favLists.forEach((list) => {
      if (list.teaching_units.includes(teachingUnitId)) newList.push(list.id);
    })
    setCheckedLists(newList);
  }

  const updateList = async (id: number, teachingUnitId: number) => {
    const url = "/api/lists/" + id;
    const listToUpdate = favLists.find((list) => list.id === id);
    if (!listToUpdate) return;
    let newTeachingUnits: number[] = [...listToUpdate.teaching_units];
    if (newTeachingUnits.includes(teachingUnitId)) newTeachingUnits = newTeachingUnits.filter(item => item !== teachingUnitId);
    else newTeachingUnits.push(teachingUnitId);
    const request: any = {};
    request.teaching_units = newTeachingUnits;
    await axios.put(url, request);
    await getLists();
    if (reloadLists) reloadLists();
  };

  const handleListSelection = async (id: number) => {
    if (checkedLists.includes(id)) setCheckedLists(checkedLists.filter((item) => item !== id));
    else setCheckedLists([...checkedLists, id]);
    await updateList(id, teachingUnitId);
  };

  const handleNewList = async () => {
    if (newListName.trim() === "") return;
    const newList = { name: newListName };
    await axios.post("/api/lists", newList);
    await getLists();
    setNewListName("");
    setShowNewListInput(false);
  };

  const handleListOpen = () => {
    closeModal();
  }
  
  return (
    <div className='saved-list'>
      {favLists.length === 0 ? <LoadingScreen modal/> :
        <>
          <div className="modal-section">
            <div className='select-list-label font-24-b p-blue-h'>Vyberte seznam</div>
            <div className='select-list-description font-16-b'>Přidat &apos;{teachingUnitName}&apos; do seznamu</div>
            <div className='fav-lists'>
              {favLists.map(list => (
                <div key={list.id} className={`list-item-wrapper ${checkedLists.includes(list.id) ? 'active' : ''}`}>
                  <div className="list-item">
                    <label className="list-label">
                        <input 
                          type="checkbox" 
                          checked={checkedLists.includes(list.id)}
                          onChange={() => handleListSelection(list.id)} 
                        /> 
                        <span className='list-name'>{list.name}</span>
                    </label>
                  </div>
                    <Link to={`/lists/${list.id}`} onClick={handleListOpen}><div className='s-blue-h font-14 display'>zobrazit</div></Link>
                </div>
              ))}
            </div>
          </div>
          <div className='new-list'>
          {!showNewListInput && <Button size='medium' onClick={() => setShowNewListInput(true)}>+ Vytvořit nový seznam</Button>}
          {showNewListInput && (
            <div className="input-section">
              <input
                type="text"
                value={newListName}
                placeholder="Název"
                onChange={(e) => setNewListName(e.target.value)}
                maxLength={63}
              />
              <button className='p-green-bg-h new-list save' onClick={handleNewList}>Uložit</button>
            </div>
          )}
          </div>
        </>}
    </div>
  );
};

export default SavedList;