import './ProfileBadge.scss';
import { useLayoutEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SettingsIcon } from '../../images/settings-icon.svg'
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { getCachedProfile } from '../../utils/getCachedProfile';
import { Link } from 'react-router-dom';

interface ProfileBadgeProps {
  userId: number | undefined;
  name?: boolean;
  border?: boolean;
  large?: boolean;
  settings?: boolean;
  nav?: boolean;
  onClick?: () => void;
  onClickSettings?: () => void;
}

const ProfileBadge = ({userId, name = false, border = false, large = false, settings = false, nav = false, onClick, onClickSettings = () => {} }: ProfileBadgeProps) => {
  const [fullName, setFullName] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");

  useLayoutEffect(() => {
    if (userId) getDetails();
  }, [userId])

  const getDetails = async () => {
    if (!userId) return
    const profile = await getCachedProfile(userId);
    setFullName(profile.fullName);
    if (profile.profilePicture) setProfilePicture(profile.profilePicture);
    else setProfilePicture("/src/images/profile-picture-default.png");
  }

  return (
    <section className='profile-badge' onClick={onClick ? onClick : () => {}}>
      <Link to={!(settings || (userId === undefined)) ? `/user?id=${userId}` : ""} className={`${!settings ? "cursor-pointer" : ""}`}>
        {userId ?
          <img src={profilePicture} className={`profile-picture ${border ? "border" : ""} ${large ? "large" : ""} ${nav ? "nav" : ""} ${!settings ? "cursor-pointer" : ""} ${userId}`} alt="profile"/>
        :
          <FontAwesomeIcon className={"user p-green-h"} icon={faUser} />
        }
        {settings &&
          <SettingsIcon className='settings p-green-h' onClick={onClickSettings}/>
        }
        {name &&
          <label className={`${large ? "font-20" : "font-14"} ${settings ? null : "cursor-pointer"}`}>{fullName}</label>
        }
      </Link>
    </section>
  );
}

export default ProfileBadge;
