import { useSelector } from "react-redux";
import Navbar from "../components/navbar/Navbar";
import ProfileSettings from "../components/profile/ProfileSettings";
import Published from "../components/profile/Published";
import './ProfilePage.scss';
import { RootState } from "redux/store";
import Footer from "components/footer/Footer";

const ProfilePage = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  return (
    <>
      <div className="profile-page">
        <Navbar />
        <div className="profile-container">
          <ProfileSettings />
          <Published header userId={userInfo.id} />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ProfilePage;