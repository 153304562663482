import './Register.scss';
import { useModal } from '../../contexts/ModalProvider';
import Login from './Login';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from "../../utils/axios";
import { useState } from 'react';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { emailSchema, firstNameSchema, lastNameSchema, passwordSchema, confirmPasswordSchema, gdprSchema } from '../../utils/validationSchemas';
import { Link } from 'react-router-dom';

type Form = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPwd: string;
  gdpr: boolean;
}

const Register = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const { showModal } = useModal();
  const { openErrorSnackbar } = useSnackbar();

  const formSchema = yup.object().shape({
    email: emailSchema,
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    password: passwordSchema,
    confirmPwd: confirmPasswordSchema,
    gdpr: gdprSchema,
  });

  const {setError, register, handleSubmit, formState: { errors } } = useForm<Form>({ 
    resolver: yupResolver(formSchema)
  });

  const handleLogin = () => {
    showModal(<Login/>);
  };

  const handleRegister = (data: Form) => {
    if (!data.gdpr) {
      setError("gdpr", {
        type: "manual",
        message: 'Toto pole je povinné',
      });
      return;
    }
    setIsApiError(false);
    axios.post("/api/users/register",{
      email: data.email,
      firstname: data.firstName,
      lastname: data.lastName,
      password: data.password,
      password_again: data.confirmPwd,
      gdpr: data.gdpr
    })
    .then(() => {
      setIsSuccessfullySubmitted(true);
      setTimeout(() => {
        showModal(<Login/>)
      }, 750);
    }).catch(err => {
      if (!err.response.data.en) {
        setIsApiError(true);
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!")
      } else if (err.response.data.en.includes("already taken")) {
        setError("email", {
          type: "server",
          message: 'Tento e-mail je již používán',
        });
      } else {
        setIsApiError(true);
        openErrorSnackbar("Někde nastala chyba zkuste to znovu!")
      }
    })
  }

  return (
    <form className='register' onSubmit={handleSubmit(handleRegister)}>
      <h1 className='font-24-b'>Zaregistrovat se</h1>
      <label className='font-14'>E-mail:</label>
      <input className={`${errors.email ? "border-red-600" : ""}`} type="email" placeholder='Zadejte e-mail...' {...register("email")}/>
      <p className={`${errors.email ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.email?.message}!</p>
      <label className='font-14'>Křestní jméno:</label>
      <input className={`${errors.firstName ? "border-red-600" : ""}`} placeholder='Zadejte křestní jméno...' {...register("firstName")}/>
      <p className={`${errors.firstName ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.firstName?.message}!</p>
      <label className='font-14'>Příjmení:</label>
      <input className={`${errors.lastName ? "border-red-600" : ""}`} placeholder='Zadejte přijmení...' {...register("lastName")}/>
      <p className={`${errors.lastName ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.lastName?.message}!</p>
      <label className='font-14'>Heslo:</label>
      <input type="password" className={`${errors.password ? "border-red-600" : ""}`} placeholder='Zadejte heslo...' {...register("password")}/>
      <p className={`${errors.password ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.password?.message}!</p>
      <label className='font-14'>Heslo znovu:</label>
      <input type="password" className={`${errors.confirmPwd ? "border-red-600" : ""}`} placeholder='Zadejte heslo znovu...' {...register("confirmPwd")}/>
      <p className={`${errors.confirmPwd ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.confirmPwd?.message}!</p>
      <div className='gdpr-wrapper-check'>
        <input id='gdpr' type="checkbox" {...register("gdpr")}/><label className='font-14' htmlFor='gdpr'>Souhlasím se <Link to={"/gdpr"} target="_blank" rel="noopener noreferrer">zpracováním osobních údajů</Link></label>
        <p className={`${errors.gdpr ? "visible" : "invisible"} ml-0.5 text-sm text-red-600`}>{errors.gdpr?.message}!</p>
      </div>
      {isSuccessfullySubmitted && (<p className="ml-0.5 text-sm text-green-600 successfullySubmitted">Registrace proběhla v pořádku.</p>)}
      {isApiError && (<p className="ml-0.5 text-sm text-red-600 successfullySubmitted">Někde nastala chyba zkuste to znovu!</p>)}
      <div className='buttons'>
        <button className='login-button s-green-bg-h p-green' type="button" onClick={handleLogin}>Přihlásit se</button>
        <button className='register-button p-green-bg-h l-green' type="submit">Zaregistrovat se</button>
      </div>
    </form>
  )
}

export default Register;