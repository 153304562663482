import { useState } from "react";
import axios from '../../utils/axios';
import { List } from "../../pages/ListPage";
import { useModal } from "../../contexts/ModalProvider";
import "./Modals.scss"
import Button from "../buttons/Button";

const EditList = ({ list, refreshLists }: { list: List, refreshLists: () => void }) => {
  const { closeModal } = useModal();
  const [name, setName] = useState(list.name);

  const handleEditList = async () => {
    if (!name) return;
    await axios.put(`/api/lists/${list.id}`, { name });
    refreshLists(); 
    closeModal();
  };

  return (
    <div className='edit-list'>
      <div className="header-wrapper">
        <h2 className="font-16-b">Přejmenujte seznam</h2>
      </div>
      <input type="text" value={name} maxLength={63} onChange={(e) => setName(e.target.value)} />
      <Button size="medium" onClick={handleEditList}>Upravit</Button>
    </div>
  );
};

export default EditList;