import React, { useState, useEffect } from "react";
import './BreadcrumbNavTeachingUnit.scss';
import {selectedSubjectIds, selectedCategoryIds, subjects, activeFilters} from 'components/filter-logic/useFilterLogic';
import { Category } from "interfaces/categories";
import { useNavigate } from "react-router-dom";
import {categoriesFiltered, categoriesParamsLoaded, categories} from 'pages/SearchPage';
import axios from "axios";
import { getCachedKeyword } from "utils/getCachedKeyword";
import { iTeachingUnit } from "interfaces/teachingUnit";

type Breadcrumb = {
  name: string;
  id: number;
}

type BreadcrumbNavTeachingUnitProps = {
  subject: number;
  category?: number;
  teachingUnit: iTeachingUnit;
}

const BreadcrumbNavTeachingUnit = ({subject, category, teachingUnit}: BreadcrumbNavTeachingUnitProps) => {
  const cleanBreadcrumb: Breadcrumb[] = [
    { name: 'Home', id: 0 },
    { name: 'Plány výuky', id: 0 },
  ];
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(cleanBreadcrumb);
  const [subjectName, setSubjectName] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (subject === 0) return;
    loadBreadcrumb();
  }, [teachingUnit])

  const loadBreadcrumb = async () => {
    const fetchedSubjectName = await getCachedKeyword(subject, "subjects");
    if (!fetchedSubjectName) return;
    setSubjectName(fetchedSubjectName)
    setBreadcrumbs([...cleanBreadcrumb, {name: fetchedSubjectName, id: subject}]);
    if (category) {
      getCategory(category);
    }
  }

  const getCategory = async (id: number) => {
    const response = await axios.get("/api/categories/" + id);
    const categories = response.data.categories;
    setBreadcrumbs((prev) => [...prev, {name: response.data.name, id: response.data.id}]);
    addCategoriesToBreadcrumbs(categories);
  }

  const addCategoriesToBreadcrumbs = (categories: Category[]) => {
    categories.forEach(category => {
      //if (breadcrumbs.slice(2, breadcrumbs.length).filter((cate) => cate.id === category.id)) return;
      setBreadcrumbs((prev) => [...prev, {name: category.name, id: category.id}]);
  
      if (category.categories) {
        addCategoriesToBreadcrumbs(category.categories);
      }
    });
  }

  const handleBreadcrumbClick = (id: number, index: number) => {
    categories.value = [];
    categoriesFiltered.value = [];
    categoriesParamsLoaded.value = true;
    if (index === 0) {
      selectedCategoryIds.value = [];
      selectedCategoryIds.value = [];
      activeFilters.value = [];
      navigate("/");
      return;
    }
    if (index === 1) {
      navigate("/search");
      if (selectedSubjectIds.value.length > 0) {
        const subjectName = subjects.value.filter(subject => subject.id === selectedSubjectIds.value[0])[0].name;
        activeFilters.value = activeFilters.value.filter(item => item.name !== subjectName);
      }
      selectedSubjectIds.value = [];
      selectedCategoryIds.value = [];
      return;
    }
    if (index > 1) {
      if (!subjectName) return;
      const newSubject = {
        id: subject,
        name: subjectName,
        type: selectedSubjectIds
      }
      const isSubjectPresent = activeFilters.value.some(filter => filter.id === newSubject.id && filter.name === newSubject.name);
      if (!isSubjectPresent) activeFilters.value = [...activeFilters.value, newSubject];
    }
    if (index === 2) {
      navigate("/search");
      selectedSubjectIds.value = [id];
      selectedCategoryIds.value = [];
      return;
    }
    navigate("/search");
    selectedSubjectIds.value = [subject];
    selectedCategoryIds.value = [id];

  };
  
  return (
    <nav className="breadcrumb-nav nav-teaching-unit">
      {breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={index}>
          <span className='item font-14' onClick={() => handleBreadcrumbClick(breadcrumb.id, index)}>
            {breadcrumb.name}
          </span>
          {index < breadcrumbs.length - 1 && <span className="separator">{" > "}</span>}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default BreadcrumbNavTeachingUnit;