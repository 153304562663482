import * as ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { Provider } from 'react-redux';
import { store } from './redux/store'
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://211fdc54194aa18414993591ca5bb185@o4506246530662400.ingest.sentry.io/4506246532497408",
  release: process.env.SENTRY_RELEASE_NAME,
  integrations: [
    new Sentry.Replay(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <App />
  </Provider>
)
