import Mutation from "./Mutation";
import { iMutations } from "interfaces/mutations";
import "./BestMutations.scss";
import PillButton from "../buttons/PillButton";
import { useModal } from "contexts/ModalProvider";
import ChooseMaterialModal from "components/upload-material/ChooseMaterialModal";
import { iMutationMaterialData } from "interfaces/materialData";
import { useAuth } from "contexts/AuthProvider";
import { useSelector } from "react-redux";

type BestMutationsProps = {
  mutations: iMutations[];
  parent: iMutationMaterialData;
};

const BestMutations = ({parent, mutations}: BestMutationsProps) => {
  const { showModal } = useModal();
  const { checkIsLoggedIn } = useAuth();
  const userInfo = useSelector((state: any) => state.auth.userInfo);

  const openModal = () => {
    checkIsLoggedIn(Boolean(userInfo.id));
    if (!userInfo.id) return;
    showModal(<ChooseMaterialModal parent={parent}/>);
  };

  return (
    <div className="best-mutations">
      <div className="heading font-20 p-blue mb-4">Nejlepší mutace</div>
      <div className="mutations-container">
        {mutations.sort((a, b) => b.number_of_downloads - a.number_of_downloads).map((data, index) => {
          return <Mutation key={index} {...data} />;
        })}
      </div>
      <div className="mutation-upload-button">
        <PillButton size="small" onClick={() => openModal()}>+ Nahrát novou mutaci</PillButton>
      </div>
    </div>
  );
};

export default BestMutations;
