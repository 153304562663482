import Button from '../buttons/Button';
import { useEffect, useState } from 'react';
import SortBy from '../sort-by/SortBy';
import './Categories.scss';

import {selectedSubjectIds, subjects, activeFilters, selectedCategoryIds} from 'components/filter-logic/useFilterLogic';
import { Category } from 'interfaces/categories';
import {categoriesFiltered, categoriesParamsLoaded} from 'pages/SearchPage';
import { findSpecificCategory, findParentIds } from 'utils/findCategoryById';
import { getCategoryRowAmount } from 'utils/getTeachingUnitRowAmount';


interface CategoriesProps {
  categoriesData: Category[];
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
}

const Categories = ({ categoriesData, setSortBy }: CategoriesProps) => {
  const [visibleCategories, setVisibleCategories] = useState<boolean>(true);
  const [numberOfCategories, setNumberOfCategories] = useState<number>(0);
  const [numberOfCategoriesRow, setNumberOfCategoriesRow] = useState<number>(0);

  useEffect(() => {
    if (categoriesData.length === 0) return;
    const numberOfCat = getCategoryRowAmount();
    setNumberOfCategoriesRow(numberOfCat)
    setNumberOfCategories(numberOfCat * 2);
    categoriesFiltered.value = categoriesData;
    if (categoriesParamsLoaded.value && (selectedSubjectIds.value.length === 1 || selectedCategoryIds.value.length === 1)) {
      const prevCategoriesFiltered = categoriesFiltered.value;
      const newCategories = prevCategoriesFiltered.reduce((acc, category) => {
        if (category.id === selectedSubjectIds.value[0] && category.categories) {
          return [...category.categories];
        } else {
          return acc;
        }
      }, [] as Category[]);

      const categoryIds = findParentIds(newCategories, selectedCategoryIds.value[0]);
      let newCategoriesFiltered = newCategories;
      for (let i = 0; i < categoryIds.length; i++) {
        const searchedCategory = findSpecificCategory(newCategoriesFiltered, categoryIds[i])[0];
        if (searchedCategory.categories) {
          newCategoriesFiltered = searchedCategory.categories;
        }
        categoriesFiltered.value = newCategoriesFiltered;
      }
      if (newCategoriesFiltered) {
        categoriesFiltered.value = newCategoriesFiltered;
      }
      categoriesParamsLoaded.value = false;
    }
  }, [categoriesData])

  useEffect(() => {
    if (categoriesParamsLoaded.value) return;
    if (categoriesData.length === 0) {
      categoriesParamsLoaded.value = true;
      return;
    }
    if (selectedSubjectIds.value.length === 0) {
      setVisibleCategories(true);
      categoriesFiltered.value = categoriesData;
      selectedCategoryIds.value = [];
    } else if (selectedSubjectIds.value.length === 1) {
      if (categoriesData.find((subject) => subject.id === selectedSubjectIds.value[0])) setVisibleCategories(true);
      else {
        setVisibleCategories(false);
      }
    } else {
      setVisibleCategories(false);
    }
  }, [selectedSubjectIds.value])

  useEffect(() => {
    if (!visibleCategories) {
      selectedCategoryIds.value = [];
      categoriesFiltered.value = categoriesData;
    }
  }, [visibleCategories])

  const handleShowMore = () => {
    setNumberOfCategories((prev) => prev + 2 * numberOfCategoriesRow);
  };

  const handleCategoryClick = (id: number, type: "subject" | "category") => {
    setNumberOfCategories(numberOfCategoriesRow * 2);
    if (type === "subject") {
      selectedSubjectIds.value = [id];
      subjects.value.forEach((subject) => {
        if (subject.id === id && !activeFilters.value.find((filter) => filter.id === subject.id)) {
          activeFilters.value = [...activeFilters.value, {id: subject.id, name: subject.name, type: selectedSubjectIds}];            
        }
      })
    } else {
      selectedCategoryIds.value = [id];
      
    }
    const prevCategoriesFiltered = categoriesFiltered.value;
    let found = false;
    const newCategories = prevCategoriesFiltered.reduce((acc, category) => {
      if (category.id === id && category.categories) {
        found = true;
        return [...category.categories];
      } else {
        return acc;
      }
    }, [] as Category[]);
  
    categoriesFiltered.value = found ? newCategories : prevCategoriesFiltered;
    
  }

  return (
    <section className='categories'>
      {visibleCategories &&
        <div className='categories-container'>
          {categoriesFiltered.value.slice(0, numberOfCategories).map((category, index) => 
            <div key={index} className={`category-item ${selectedCategoryIds.value.includes(category.id) ? "selected" : ""}`} onClick={() => handleCategoryClick(category.id, selectedSubjectIds.value.length === 0 ? "subject" : "category")}>
              <span className='font-14'>{category.name}</span>
            </div> 
          )}
        </div>
      }
      <div className='upper-header'>
        {visibleCategories && numberOfCategories < categoriesFiltered.value.length && (
          <Button color={"secondary"} size={"medium"} onClick={handleShowMore} className='more-categories s-blue-bg-h'>Zobrazit další kategorie</Button>
        )}
        <SortBy setSortBy={setSortBy}/>
      </div>
    </section>
  );
};

export default Categories;