import Fuse from 'fuse.js';
import { getCachedObjects } from './getCachedKeyword';

export const findSubject = async (subjectName: string) => {
  const subjects = await getCachedObjects("subjects");

  const normalize = (text: string) => {
    const map: { [key: string]: string } = {
        'á': 'a', 'č': 'c', 'ď': 'd', 'é': 'e', 'ě': 'e', 'í': 'i',
        'ň': 'n', 'ó': 'o', 'ř': 'r', 'š': 's', 'ť': 't', 
        'ú': 'u', 'ů': 'u', 'ý': 'y', 'ž': 'z'
    };
    return text.toLowerCase().split('').map(char => map[char] || char).join('');
  }

  const getFn = (obj: any, path: string | string[]) => {
    const value = Fuse.config.getFn(obj, path);
    if (typeof value === 'string') {
      return normalize(value);
    }
      return value;
  }

  const getStrictFn = (obj: any, path: string | string[]) => {
    const value = Fuse.config.getFn(obj, path);
    if (typeof value === 'string') {
      return value.toLowerCase();
    }
      return value;
  }
  
  const strictOptions = {
    keys: ['name'],
    getStrictFn,
    includeScore: true,
    shouldSort: true,
    findAllMatches: true,
    location: 0,
    distance: 0
  }

  const options = {
    keys: ['name'],
    getFn,
    includeScore: true,
    shouldSort: true,
    findAllMatches: true,
  }

  if (subjectName.length < 3) return {"id": 0, "name": "Předmět"};
  

  const strictFuse = await new Fuse(subjects, strictOptions);

  const normalizedSubjectName = normalize(subjectName.toLowerCase());

  if (normalizedSubjectName.length < 3) return {"id": 0, "name": "Předmět"};
  
  const results = await strictFuse.search(subjectName.slice(0,3));
  if (results.length > 0) {
    if (results[0] && results[0].score) {
      return results[0].item;
    }
  }

  if (subjectName.length === 3) return {"id": 0, "name": "Předmět"};

  const fuse = await new Fuse(subjects, options);

  const secondResults = await fuse.search(normalizedSubjectName);
  if (secondResults.length > 0) {
    return secondResults[0].item;
  }

  return {"id": 0, "name": "Předmět"};
}