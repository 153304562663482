import "./RatingsWindow.scss";
import Reviews from "./review/Reviews";
import RatingModal from "./rating-modal/RatingModal";
import { useModal } from "../../../contexts/ModalProvider";
import Button from "../../buttons/Button";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { iReviewData } from "../../../interfaces/review";
import { useSelector } from "react-redux";
import LoadingScreen from "../../loading-screen/LoadingScreen";
import PillButton from "../../buttons/PillButton";

interface RatingsWindowProps {
  id: number;
}

const RatingsWindow = ({id}: RatingsWindowProps) => {
  const [ratings, setRatings] = useState<iReviewData[]>([]);
  const { showModal } = useModal();
  const reloadTeachingUnit = useSelector((state: any) => state.utils.reloadTeachingUnit);
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const [nextPage, setNextPage] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadRatings();
  }, [id])

  useEffect(() => {
    if (reloadTeachingUnit === "ratings") loadRatings();
  }, [reloadTeachingUnit]);

  const loadRatings = async () => {
    const response = await axios.get(`/api/teaching-units/${id}/ratings/list`);
    setRatings(response.data.results);
    if (response.data.next) setNextPage(response.data.next);
    setFirstLoad(false);
  }

  const loadMoreRatings = async () => {
    setLoading(true);
    const response = await axios.get(nextPage);
    setRatings([...ratings, ...response.data.results]);
    if (response.data.next) setNextPage(response.data.next);
    else setNextPage("");
    setLoading(false);
  }

  return (
    <section className="ratings-window">
      {userInfo.id &&
        <PillButton size="medium" color="primary" className="add-rating-button" onClick={() => showModal(<RatingModal id={id}/>)}>+ Přidat hodnocení</PillButton>
      }
      {firstLoad ? 
        <LoadingScreen upper/>
      :
        <>
          {ratings.map((data, index) => {
            return <Reviews key={index} {...data} editable={(userInfo.is_staff || userInfo.id === data.author)} idOfTeachingUnit={id} />;
          })}
          {ratings.length === 0 && <p className="font-16-b no-ratings">Tato výuka zatím nemá žádná hodnocení</p>}
          {nextPage &&
            <>
              {loading ?
                <LoadingScreen upper/>
              :
                <div className="load-more">
                  <Button size="medium" color="secondary" className="load-more-button" onClick={() => loadMoreRatings()}>Načíst další</Button>
                </div>
              }
            </>
          }
        </>
      }
    </section>
  )
}

export default RatingsWindow;