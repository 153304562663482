import './LessonPreviewButton.scss'
import { ReactComponent as ArrowRight } from '../../../../images/arrow-right.svg'
import { ReactComponent as ArrowLeft } from '../../../../images/arrow-left.svg'

const LessonPreviewButton = (props: any) => {
  return (
    <button
      className={`lesson-preview-button font-14 ${props.open ? 'l-blue-bg' : 'white-bg-h'}`}
      onClick={props.onSelect}
    >
      <span>{props.children}</span>
      {props.open ? <ArrowLeft className='p-blue'/> : <ArrowRight className='p-blue'/>}
    </button>
  )
}
export default LessonPreviewButton;