import { useState } from "react";
import { useModal } from "../../contexts/ModalProvider";
import Button from "../buttons/Button";
import "./GeneralModal.scss";

export interface GeneralModalProps {
  text: string;
  actionOnClick?: (() => void) | undefined;
  input?: string;
  actionOnClickWparam?: (inputValue: string) => void;
}

const GeneralModal = ({ text, actionOnClick, input, actionOnClickWparam }: GeneralModalProps) => {
  const { closeModal } = useModal();
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <div className="general-modal">
      <h1 className="font-16-b">{text}</h1>
      {input &&
        <> 
          <h2>{input}</h2>
          <input type="password" className="input" onChange={(e) => setInputValue(e.target.value)} />
        </>
      }
      <div className="buttons">
        <Button color="lightgreen" size="medium" className="close-button p-green" onClick={closeModal}>Zrušit</Button>
        {actionOnClickWparam ?
          <Button color="lightgreen" size="medium" className="remove-button l-red-bg-h" onClick={() => actionOnClickWparam(inputValue)}>Smazat</Button>
        :
          <Button color="lightgreen" size="medium" className="remove-button l-red-bg-h" onClick={actionOnClick}>Smazat</Button>
        }
      </div>
    </div>
  );
};

export default GeneralModal;