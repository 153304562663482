import { useState } from "react";
import axios from '../../utils/axios';
import { useModal } from "../../contexts/ModalProvider";
import "./Modals.scss"
import Button from "../buttons/Button";

const AddList = ({ refreshLists }: { refreshLists: () => void }) => {
  const { closeModal } = useModal();
  const [newListName, setNewListName] = useState("");

  const handleAddList = async () => {
    if (!newListName) return;
    await axios.post('/api/lists', { name: newListName });
    refreshLists(); 
    closeModal();
  };
  
  return (
    <div className='add-list'>
      <div className="header-wrapper">
        <h2 className="font-16-b">Vytvořte nový seznam</h2>
      </div>
      <input type="text" placeholder="Nový seznam" maxLength={63} onChange={e => setNewListName(e.target.value)} />
      <Button size="medium" onClick={handleAddList}>Přidat seznam</Button>
    </div>
  );
};

export default AddList;