import { createSlice } from '@reduxjs/toolkit'

export const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    reloadTeachingUnit: "" as string,
  },
  reducers: {
    setReloadTeachingUnit: (state, action) => {
        state.reloadTeachingUnit = action.payload
    }
  },
})

export const {
  setReloadTeachingUnit
} = utilsSlice.actions

export default utilsSlice.reducer