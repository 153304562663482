import { iProfile } from "../interfaces/profile";
import axios from "./axios";

export const getCachedProfile = async (userId: number) => {
  const profile: iProfile = {fullName: "", profilePicture: ""};
  const cachedObject = sessionStorage.getItem("profile" + userId);
  if (!cachedObject) {
    const response = await axios.get("/api/users/user/public/" + userId);
    const loadedObject = response.data;
    const loadedFullName = loadedObject.first_name + " " + loadedObject.last_name;
    sessionStorage.setItem("profile" + userId, JSON.stringify({"name": loadedFullName, "profilePicture": loadedObject.profile_image}));
    profile.fullName = loadedFullName;
    profile.profilePicture = loadedObject.profile_image;
    return profile;
  } else {
    const parsedObject = JSON.parse(cachedObject);
    profile.fullName = parsedObject.name;
    profile.profilePicture = parsedObject.profilePicture;
    return profile;
  }
}