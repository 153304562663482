import "./Searchbar.scss";
import { ReactComponent as SearchIcon } from '../../images/search-icon.svg';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { iFilterTag } from "../../interfaces/filter";
import Button from "../buttons/Button";
import { Signal } from "@preact/signals-react";

import {
  selectedSubjectIds,
  selectedGradeTypeIds,
  selectedGradeIds,
  certificated,
  selectedLanguageIds,
  rating,
  mutations,
  activeTags,
} from 'components/filter-logic/useFilterLogic'

interface SearchBarProps {
  activeFilters: Signal<iFilterTag[]>;
  addTag: (tag: string) => void;
  handleSearch?: () => void;
  button?: boolean;
  searchContext: string;
  setSearchContext: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar = ({ activeFilters, addTag, handleSearch, button = true, setSearchContext, searchContext }: SearchBarProps) => {

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchContext === '') {
      if (activeTags.value.length > 0) activeTags.value = activeTags.value.slice(0,-1);
    } else if (e.key === 'Enter') {
      e.preventDefault(); 
      if (searchContext.trim() !== '') {
        addTag(searchContext.trim());  
        setSearchContext('');
      }
    }
  };

  const removeFilter = (filter: iFilterTag) => {
    if (filter.type === selectedSubjectIds) {selectedSubjectIds.value = selectedSubjectIds.value.filter(item => item !== filter.id)}
		else if (filter.type === selectedGradeTypeIds) {selectedGradeTypeIds.value = selectedGradeTypeIds.value.filter(item => item !== filter.id)}
		else if (filter.type === selectedGradeIds) {selectedGradeIds.value = selectedGradeIds.value.filter(item => item !== filter.id)}
		else if (filter.type === selectedLanguageIds) {selectedLanguageIds.value = selectedLanguageIds.value.filter(item => item !== filter.id)}
		else if (filter.type === rating) {rating.value = rating.value.filter(item => item !== filter.id)}
		else if (filter.type === certificated) {certificated.value = certificated.value.filter(item => item !== filter.id)}
		else if (filter.type === mutations) {mutations.value = mutations.value.filter(item => item !== filter.id)}
    activeFilters.value = activeFilters.value.filter(item => item.name !== filter.name);
  }

  return (
    <div className='searchbar'>
      <div className="keywords-container-left">
        <div className="keywords-container">
          <label className="font-14 select-none">Klíčová slova: </label>
          <div className="keywords">
            {activeFilters.value.length || activeTags.value.length ? null  :
              <span className="font-12 mt-1 select-none">Pro přidání klíčového slova stikněte Enter...</span>
            }
            {activeFilters.value.map((filter, index) => (
              <div key={index} className="filter-tag p-green-bg">
                <div className="filter-keyword font-12 select-none">{filter.name}</div>
                <CloseIcon className="close-icon" onClick={() => removeFilter({"name": filter.name, "id": filter.id, "type": filter.type})} />
              </div>
            ))}
            {activeTags.value.map((tag, index) => (
              <div key={index} className="filter-tag">
                <div className="filter-keyword font-12 select-none">{tag}</div>
                <CloseIcon className="close-icon" onClick={() => {activeTags.value = activeTags.value.filter((sTag: string, index: number) => { return !(sTag === tag && index === activeTags.value.indexOf(tag))})}} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="searchbar-input">
        <SearchIcon className="p-green search-icon" />
        <input 
          onChange={(e) => setSearchContext(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="+  Přidejte více..." 
          value={searchContext} 
        />
        {button &&
          <Button className='px-[22px]' size={"small"} color={"primary"} onClick={handleSearch}>Hledej</Button>
        }
      </div>
    </div>
  );
};

export default SearchBar;