import './App.css';
import "./globalstyle.scss";
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from './contexts/ProtectedRoute';
import SnackbarProvider from "./contexts/SnackbarProvider";
import { ModalProvider } from "./contexts/ModalProvider";
import { setError } from './redux/errorSlice';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from './utils/ErrorHandling';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import ProfilePage from './pages/ProfilePage';
import UserPage from './pages/UserPage';
import TeachingUnitPage from './pages/TeachingUnitPage';
import ListPage from './pages/ListPage';
import { AuthProvider } from './contexts/AuthProvider';
import GdprPage from 'pages/GdprPage';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Global error handler for uncaught exceptions
    window.onerror = (message, source, lineno, colno, error) => {
      console.error('App handler: Uncaught Error:', { message, source, lineno, colno, error });
      dispatch(setError(JSON.stringify({ message, source, lineno, colno, error })));
    }

    // Global handler for unhandled promise rejections
    window.onunhandledrejection = (event) => {
      console.error('App handler: Unhandled Promise Rejection:', event.promise, 'Reason:', event.reason);
      dispatch(setError(JSON.stringify({ event })));
    }
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <SnackbarProvider>
          <ModalProvider>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                <Route path="/me" element={<ProtectedRoute userIsNeeded><ProfilePage /></ProtectedRoute>} />
                <Route path="/search" element={<ProtectedRoute><SearchPage /></ProtectedRoute>} />
                <Route path="/user" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
                <Route path="/teaching-unit/:id" element={<ProtectedRoute><TeachingUnitPage /></ProtectedRoute>} />
                <Route path="/lists" element={<ProtectedRoute userIsNeeded><ListPage /></ProtectedRoute>} />
                <Route path="/lists/:id" element={<ProtectedRoute userIsNeeded><ListPage /></ProtectedRoute>} />
                <Route path="/gdpr" element={<ProtectedRoute><GdprPage /></ProtectedRoute>} />
              </Routes>
            </AuthProvider>
          </ModalProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ErrorBoundary >
  )
}

export default App