import './SearchUnit.scss'
import CertifiedBadge from "../certified-badge/CertifiedBadge";
import HeartButton from "../buttons/HeartButton";
import Rating from "../rating/Rating";
import ProfileBadge from "../profile-badge/ProfileBadge";
import Keyword from "../keyword/Keyword";
import ViewButton from '../buttons/ViewButton';
import { iTeachingUnit } from '../../interfaces/teachingUnit';
import { useEffect, useState } from 'react';
import { getCachedKeyword } from '../../utils/getCachedKeyword';
import { htmlToPlainText } from '../../utils/htmlToPlainText';
import { Link } from 'react-router-dom';

const SearchUnit = ({ id, name, number_of_lessons, _tags, description, average_rating, number_of_ratings, number_of_downloads, certificated, author, grade, subject, mutations, grade_type }: iTeachingUnit) => {
  const [subjectName, setSubjectName] = useState<string | null>(null);
  const [gradeName, setGradeName] = useState<string | null>(null);
  const [gradeTypeName, setGradeTypeName] = useState<string | null>(null);

  useEffect(() => {
    if (subject) fetchKeyword("subjects", setSubjectName, subject);
    if (grade) fetchKeyword("grades", setGradeName, grade);
    if (grade_type) fetchKeyword("grade-types", setGradeTypeName, grade_type);
  }, [id]);

  const fetchKeyword = async (type: string, setter: React.Dispatch<React.SetStateAction<string | null>>, value: number) => {
    const name = await getCachedKeyword(value, type);
    setter(name);
  };

  return (
    <section className="search-unit">
      <div className="unit-header">
        <span className='unit-heading p-blue font-20-b'>
          <Link to={`/teaching-unit/${id}`}><span className='unit-label'>{name}</span></Link>
          <HeartButton teachingUnitId={id} teachingUnitName={name}/>
        </span>
      </div>
      <div className="unit-main">
        <div className="unit-content">
          <div className='flex'>
            <ProfileBadge userId={author} name={true}/>
          </div>
          <ul className="unit-keywords">
            <>
              {subjectName && <Keyword key={subjectName} keyword={subjectName}/>}
              {gradeTypeName && <Keyword key={gradeTypeName} keyword={gradeTypeName}/>}
              {gradeName && <Keyword key={gradeName} keyword={gradeName}/>}
              {_tags.map((keyword, index) => (
                <Keyword key={index} keyword={keyword.name}></Keyword>
              ))}
            </>
          </ul>
          <p className='unit-description'>{htmlToPlainText(description)}</p>
          <div className="unit-footer">
            <div className="unit-rating-and-downloads">
              <span className='unit-rating font-14'>{number_of_ratings} hodnocení</span>
              <span className='unit-downloads font-14'>{number_of_downloads} stažení</span>
            </div>
            <Rating rating={average_rating ? Math.round(average_rating * 10) / 10 : 0}/>
            {certificated &&
              <CertifiedBadge text={true} large={false}/>
            }
          </div>
        </div>
        <aside className="unit-aside">
          <div className='unit-lessons-and-mutations'>
            <span className='font-16-b'>set {number_of_lessons} {number_of_lessons === 1 ? "hodiny" : "hodin"}</span>
            <span className='font-14'>{mutations.length} {mutations.length > 4 ? "mutací" : <>{mutations.length === 0 ? "mutací" : "mutace"}</>}</span>
          </div>
          <ViewButton id={id}/>
        </aside>
      </div>
    </section>
  )
}
export default SearchUnit;