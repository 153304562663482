import './ViewButton.scss'
import { Link } from 'react-router-dom';

interface ViewButtonProps {
  id: number;
}

const ViewButton = ({id}: ViewButtonProps) => {

  return (
    <Link to={`/teaching-unit/${id}`}><button className='view-button p-green-bg-h font-20-b'>Zobrazit</button></Link>
  )
}
export default ViewButton;