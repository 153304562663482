import { useEffect } from "react";
import { iGrade, iGradeType, iLanguage, iSubject, iFilterTag, iRating, iCertificated, iMutations } from "../../interfaces/filter";
import axios from "../../utils/axios";
import Fuse from 'fuse.js';

import { Signal, signal } from "@preact/signals-react";

export const subjects = signal<iSubject[]>([]);
export const selectedSubjectIds = signal<number[]>([]);
export const gradeTypes = signal<iGradeType[]>([]);
export const selectedGradeTypeIds = signal<number[]>([]);
export const grades = signal<iGrade[]>([]);
export const selectedGradeIds = signal<number[]>([]);
export const certificated = signal<number[]>([]); //0 = without; 1 = with
export const languages = signal<iLanguage[]>([]);
export const selectedLanguageIds = signal<number[]>([]);
export const rating = signal<string[]>([]);
export const filteredGrades = signal<iGrade[]>([]);
export const filteredSubjects = signal<iSubject[]>([]);
export const subjectFilter = signal<string>("");
export const mutations = signal<number[]>([]); //0 = without; 1 = with
export const selectedCategoryIds = signal<number[]>([]);
export const filterQuery = signal<string>("");
export const activeFilters = signal<iFilterTag[]>([]);
export const activeTags = signal<string[]>([]);
export const staticRating = signal<iRating[]>([]);
export const staticCertificated = signal<iCertificated[]>([]);
export const staticMutations = signal<iMutations[]>([]);

export const selectedGradeTypeIdsUpload = signal<number[]>([]);
export const filteredGradesUpload = signal<iGrade[]>([]);

export default () => {

  useEffect(() => {
    objects.forEach((object: iObjectWithSetter) => {
      getObject(object)
    })
    staticRating.value = [
      {
        id: "5-4",
        name: "5-4 hvězdy"
      },{
        id: "4-3",
        name: "4-3 hvězdy"
      },{
        id: "3-2",
        name: "3-2 hvězdy"
      },{
        id: "2-1",
        name: "2-1 hvězdy"
      }
    ]
    staticCertificated.value = [
      {
        id: 1,
        name: "Certifikováno"
      },{
        id: 0,
        name: "Bez certifikace"
      }
    ]
    staticMutations.value = [
      {
        id: 1,
        name: "Pouze mutace"
      },{
        id: 0,
        name: "Celky a mutace"
      }
    ]
  }, [])

  useEffect(() => {
    if (selectedGradeTypeIds.value.length === 0) filteredGrades.value = grades.value
    else {
      const newSelectedGrades: iGrade[] = grades.value.filter(grade => selectedGradeTypeIds.value.includes(grade.grade_type));
      const oldSelectedGrades: iGrade[] = grades.value.filter(grade => selectedGradeIds.value.includes(grade.id));
      const combinedArray = newSelectedGrades.concat(oldSelectedGrades).filter((obj, index, self) => index === self.findIndex((el) => el.id === obj.id && el.name === obj.name));
      filteredGrades.value = combinedArray;
    }
  }, [selectedGradeTypeIds.value])

  useEffect(() => {
    if (selectedGradeTypeIdsUpload.value.length === 0) filteredGradesUpload.value = grades.value
    else {
      const newSelectedGrades: iGrade[] = grades.value.filter(grade => selectedGradeTypeIdsUpload.value.includes(grade.grade_type));
      const oldSelectedGrades: iGrade[] = grades.value.filter(grade => selectedGradeIds.value.includes(grade.id));
      const combinedArray = newSelectedGrades.concat(oldSelectedGrades).filter((obj, index, self) => index === self.findIndex((el) => el.id === obj.id && el.name === obj.name));
      filteredGradesUpload.value = combinedArray;
    }
  }, [selectedGradeTypeIdsUpload.value])
  
  useEffect(() => {
    filteredSubjects.value = subjects.value
  }, [subjects.value])

  useEffect(() => {
    filteredGrades.value = grades.value
  }, [grades.value])

  useEffect(() => {
    filteredGradesUpload.value = grades.value
  }, [grades.value])

  const normalize = (text: string) => {
    const map: { [key: string]: string } = {
      'á': 'a', 'č': 'c', 'ď': 'd', 'é': 'e', 'ě': 'e', 'í': 'i',
      'ň': 'n', 'ó': 'o', 'ř': 'r', 'š': 's', 'ť': 't', 
      'ú': 'u', 'ů': 'u', 'ý': 'y', 'ž': 'z'
    };
    return text.toLowerCase().split('').map(char => map[char] || char).join('');
  }

  const getFn = (obj: any, path: string | string[]) => {
    const value = Fuse.config.getFn(obj, path);
    if (typeof value === 'string') {
      return normalize(value);
    }
    return value;
  }
  
  const options = {
    keys: ['name'],
    shouldSort: true,
    getFn
  }
  
  const fuse = new Fuse(subjects.value, options);

  const handleSubjectFilter = (e: any) => {
    subjectFilter.value = e.target.value;
    const filter: string = e.target.value;
    const result = fuse.search(normalize(filter.toLowerCase()));
    const newFilteredSubjects: iSubject[] = [];
    result.forEach(({ item }) => {
      newFilteredSubjects.push({"id": item.id, "name": item.name});
    });
    if (newFilteredSubjects.length === 0) filteredSubjects.value = subjects.value;
    else filteredSubjects.value = newFilteredSubjects;
  }

  interface iObjectWithSetter {
    name: string;
    setter: Signal<any[]>;
  }

  const objects: iObjectWithSetter[] = [
    {"name": "subjects", "setter": subjects},
    {"name": "grade-types", "setter": gradeTypes},
    {"name": "grades", "setter": grades},
    {"name": "languages", "setter": languages},
  ]

  const getObject = async (object: iObjectWithSetter) => {
    const cachedObject = sessionStorage.getItem(object.name);
    if (!cachedObject) {
      const response = await axios.get("/api/" + object.name);
      const loadedObject = response.data;
      if (object.setter === subjects) loadedObject.sort((a: iSubject, b: iSubject) => a.id - b.id);
      sessionStorage.setItem(object.name, JSON.stringify(loadedObject));
      object.setter.value = loadedObject;
    } else {
      const parsedObject = JSON.parse(cachedObject);
      object.setter.value = parsedObject;
    }
  }

  const flushFilters = () => {
    selectedSubjectIds.value = [];
    selectedGradeTypeIds.value = [];
    selectedGradeIds.value = [];
    certificated.value = [];
    selectedLanguageIds.value = [];
    rating.value = [];
    subjectFilter.value = "";
    mutations.value = [];
    filterQuery.value = "";
    activeFilters.value = [];
    selectedCategoryIds.value = [];
  }

  return {
    subjects,
    selectedSubjectIds,
    gradeTypes,
    selectedGradeTypeIds,
    grades,
    selectedGradeIds,
    certificated,
    languages,
    selectedLanguageIds,
    rating,
    filteredGrades,
    filteredSubjects,
    handleSubjectFilter,
    subjectFilter,
    mutations,
    flushFilters,
    staticCertificated,
    staticMutations,
    staticRating
  }
}