import axios from "axios";
import { Category } from "interfaces/categories";

type TSubjectWithCategories = {
    id: number,
    name: string,
    categories: TCategoryObject[]
};

const blankSubjectWithCategories = {
    id: 0,
    name: "",
    categories: []
};

type TCategoryObject = {
    id: number,
    name: string,
    children?: TCategoryObject[]
};

const blankCategory = {
    id: 0,
    name: "Kategorie",
};

export const getCachedCategories = async () => {
    const cachedObject = sessionStorage.getItem("categories/subjects");
    if (cachedObject) {
        const objects: TSubjectWithCategories[] = JSON.parse(cachedObject);
        if (objects) {
            return objects;
        }
    }
    
    const response = await axios.get("/api/categories/subjects");
    if (response.status === 404) {
        return [blankSubjectWithCategories];
    }
    const loadedObject: TSubjectWithCategories[] = response.data.sort((a:Category, b:Category) => a.id - b.id);
    sessionStorage.setItem("categories", JSON.stringify(loadedObject));
    if (loadedObject) {
        return loadedObject;
    }

    return [blankSubjectWithCategories];
};


export const getSpecificCategory = async (id: number | undefined) => {
    if (!id) return blankCategory;
    const cachedObject = sessionStorage.getItem("categories/detail/" + id)
    if (cachedObject) {
        const objects: TCategoryObject = JSON.parse(cachedObject);
        if (objects) {
            return objects;
        }
    }
    
    const response = await axios.get("/api/categories/detail/" + id);
    if (response.status === 404) {
        return blankCategory;
    }
    const loadedObject: TCategoryObject = response.data;
    sessionStorage.setItem("categories/detail/" + id, JSON.stringify(loadedObject));
    if (loadedObject) {
        return loadedObject;
    }

    return blankCategory;
};
