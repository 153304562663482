import { useEffect, useState } from 'react'
import './PreviewWindow.scss'
import LessonPreviewButton from './lesson-preview-button/LessonPreviewButton'
import LoadingScreen from '../../loading-screen/LoadingScreen';
import { timeout } from '../../../utils/timeout';
import axios from '../../../utils/axios';
import { iLessonPlan } from '../../../interfaces/lessonPlans';
import { useSnackbar } from '../../../contexts/SnackbarProvider';

interface TeachingUnitPageProps {
  id: string | undefined;
}

const PreviewWindow = ({id}: TeachingUnitPageProps) => {
  const [selectedLessonPreview, setSelectedLessonPreview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeIframeLink, setActiveIframeLink] = useState<string>("");
  const [plans, setPlans] = useState<iLessonPlan[]>([]);
  const [fullLoading, setFullLoading] = useState<boolean>(true);
  const { openErrorSnackbar } = useSnackbar();
  
  useEffect(() => {
    if (!id) return;
    getPlans();
  }, [])

  useEffect(() => {
    if (!activeIframeLink || plans.length === 0) return;
    setFullLoading(false);
    const iframe = document.querySelector('.preview-iframe') as HTMLIFrameElement;

    if (iframe) {
      axios.get(activeIframeLink)
        .then((response) => {
          const stringHtml = response.data;
          const parser = new DOMParser();
          const iframeDocument = parser.parseFromString(stringHtml, 'text/html');

          if (iframeDocument) {
            const links = iframeDocument.querySelectorAll('link');
            links.forEach((link: any) => {
              if (link.getAttribute('href')?.includes('.css')) link.remove();
            });
            const scripts = iframeDocument.querySelectorAll('script');
            scripts.forEach((script: any) => {
              if (script.getAttribute('src')?.includes('alert.js') || script.getAttribute('src')?.includes('dom.js')) script.remove();
            });
            const images = iframeDocument.querySelectorAll('img');
            images.forEach((image: any) => {
              const currentSrc = image.getAttribute('src');
              if (currentSrc) image.setAttribute('src', '/src/static/icons/' + currentSrc.substring(currentSrc.lastIndexOf('/') + 1));
            });

            const linkPdf = iframeDocument.createElement('link');
            linkPdf.type = 'text/css';
            linkPdf.rel = 'stylesheet';
            linkPdf.href = '/src/static/pdf.css';
            const linkMain = iframeDocument.createElement('link');
            linkMain.type = 'text/css';
            linkMain.rel = 'stylesheet';
            linkMain.href = '/src/static/main.css';

            const iframeHead = iframeDocument.querySelector('head');
            if (iframeHead) {
              iframeHead.appendChild(linkPdf);
              iframeHead.appendChild(linkMain);
            }

            const iframeHtml = iframeDocument.documentElement.outerHTML;

            iframe.srcdoc = iframeHtml;
            setLoadedOffset();
          }
        }).catch((error) => {
          console.error('Error fetching HTML:', error);
        }
      );
    }
  }, [activeIframeLink]);

  const getPlans = async () => {
    const response = await axios.get(`/api/teaching-units/${id}/plan`);
    if (response.data.lessons.length === 0) {
      openErrorSnackbar("Nepodařilo se načíst plány hodin!");
      setFullLoading(false);
      return;
    }
    setPlans(response.data.lessons);
    setActiveIframeLink(response.data.lessons[0].preview_file);
  }

  const toggleSelectedPreview = (index: number) => {
    if (index === selectedLessonPreview) return;
    setLoading(true);
    setSelectedLessonPreview(index);
    setActiveIframeLink(plans[index].preview_file);
  }

  const setLoadedOffset = async () => {
    await timeout(100);
    setLoading(false);
  }

  return (
    <section className="preview-window">
      {fullLoading ?
        <LoadingScreen upper/>
      :
        <>
          <div className="lessons-list">
            {
              plans.map((lesson, index) => {
                return (
                  <LessonPreviewButton
                    key={index}
                    open={selectedLessonPreview === index}
                    onSelect={() => toggleSelectedPreview(index)}
                  >
                    {lesson.name}
                  </LessonPreviewButton>
                )
              })
            }
          </div>
          {loading && <LoadingScreen upper/>}
        </>
      }
      <iframe
        className={'preview-iframe' + (loading || fullLoading ? ' hidden' : '')}
        title='Náhled hodiny'
      />
    </section>
  )
}

export default PreviewWindow;