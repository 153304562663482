import "./Keyword.scss";

export interface KeywordProps {
  keyword: string;
}

const Keyword = ({ keyword }: KeywordProps) => {
  return (
    <li className="keyword font-12 s-green-bg">{keyword}</li>
  );
};

export default Keyword;