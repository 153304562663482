import { ReactComponent as StarIcon } from '../../images/star.svg'
import Tooltip from '../tooltip/Tooltip';
import "./Rating.scss"

export interface RatingProps {
  rating: number;
}

const Rating = ({ rating }: RatingProps) => {
  return (
    <Tooltip text={`Hodnocení: ${rating}/5`}>
      <div className="rating l-purple-bg-h">
        <StarIcon className='rating-icon'/>
        <span className='font-14'>{rating}/5</span> 
      </div>
    </Tooltip>
  );
};

export default Rating;